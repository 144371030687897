import React, { useState } from 'react';
import { SessionTestsService } from '../../../../../services/api/commonServices';

export default function Notes({params}) {
  const { data } = params;
  // Warning `value` prop on `textarea` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
  let [notes, setNotes] = useState(() => (data?.util_sess_notes ? data.util_sess_notes : ""));

  let [isLoading, setIsLoading] = useState(false);
  let [isChanged, setIsChanged] = useState(false);

  const handleSave = () => {
    setIsLoading(true);
    SessionTestsService()
      .patch(data.util_sess_id, { notes: notes })
      .then((resp) => {
        setIsLoading(false);
        setIsChanged(false);
        // params.node.setDataValue({'util_sess_notes': notes});
        // var rowNode = params.api.getRowNode(data.id);
        // console.log(rowNode);
        // console.log(rowNode.setDataValue);
        // params.node.setDataValue('util_sess_notes', notes)
        // console.log(params.api.applyTransaction({update: params.node}))
        data.util_sess_notes = notes; // Hack because none of the above works... (in order to prevent re-rendering the row, which would close 'Notes' tab)
      })
      .catch(() => {
        setIsLoading(false);
        setNotes(data.util_sess_notes);
      });
  };

  return (
    <>
      <textarea
        style={{ width: "100%", height: "200px", padding: "10px" }}
        value={notes}
        onChange={(e) => {
          setIsChanged(true);
          setNotes(e.target.value);
        }}
        disabled={isLoading}></textarea>
      <div className='buttons mt-2'>
        <button className='button is-primary is-small' onClick={() => handleSave()} disabled={isLoading || isChanged === false}>
          Enregistrer
        </button>
        <button
          className='button is-small'
          onClick={(e) => {
            setNotes(data.notes);
            setIsChanged(false);
          }}
          disabled={isLoading || isChanged === false}>
          Annuler
        </button>
      </div>
    </>
  );
}
