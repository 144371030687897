import React, { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import AuthService from "../../services/api/authService";
import { useAuth } from "../../utils/use-auth.js";

export default function Login() {
  let auth = useAuth();
  let history = useHistory();

  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    AuthService.login(email, password)
      .then((response) => {
        auth.signin(response, () => {
          history.replace(from);
        });
      })
      .catch((response) => {
        alert(`Denied`);
      });
  };

  if (auth.user) {
    return <Redirect to="/" />;
  }
  return (
    <div className="container">
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-5-tablet is-4-desktop">
            <h1 className="title is-1">Connexion</h1>
            <form className="form" onSubmit={handleSubmit}>
              <div className="field">
                <label className="label">Courriel</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Mot de passe</label>
                <div className="control">
                  <input
                    className="input"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="field is-grouped">
                <div className="control">
                  <button
                    type="submit"
                    className="button is-primary"
                    disabled={!validateForm()}
                  >
                    Se connecter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
