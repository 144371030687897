import React, { useEffect, useState } from "react";

const ToBeHandledFilter = ({ gridRef, cohortCandidatesData }) => {
  const [toBeHandledTotal, setToBeHandledTotal] = useState(0);

  useEffect(() => {
    let toBeHandledTotal = 0;
    cohortCandidatesData?.forEach((rowNode) => {
      if (rowNode.to_be_handled === 1) {
        toBeHandledTotal += 1;
      }
    });
    setToBeHandledTotal(toBeHandledTotal);
  }, [cohortCandidatesData]);

  const onChange = (checked) => {
    let instance = gridRef.current.api.getFilterInstance("to_be_handled");
    if (checked === false) {
      instance.setModel(null);
    } else {
      instance.setModel({ type: "equal", filter: "Oui" });
    }
    instance.applyModel();
    gridRef.current.api.onFilterChanged();
  };

  return (
    <div id="to-be-handle-filter" className="field">
      <label className="label is-small has-text-weight-normal">
        À traiter{" "}
        <span
          className="has-text-grey"
          hidden={typeof toBeHandledTotal === "undefined"}
        >
          ({toBeHandledTotal})
        </span>
      </label>
      <input
        type="checkbox"
        className="checkbox"
        name="to_be_handled"
        value="Oui"
        onChange={(e) => onChange(e.target.checked)}
        style={{ marginRight: "0.5rem" }}
      />
    </div>
  );
};
export default ToBeHandledFilter;
