import React, { useState, useCallback, useContext } from "react";

export const APIErrorContext = React.createContext({
  apiError: null,
  addApiError: () => {},
  removeApiError: () => {},
});

export function useAPIError() {
  const { apiError, addApiError, removeApiError } = useContext(APIErrorContext);
  return { apiError, addApiError, removeApiError };
}

export default function APIErrorProvider({ children }) {
  const [apiError, setApiError] = useState(null);

  const removeApiError = () => setApiError(null);

  const addApiError = (message, status) => setApiError({ message, status });

  const contextValue = {
    apiError,
    addApiError: useCallback(
      (message, status) => addApiError(message, status),
      []
    ),
    removeApiError: useCallback(() => removeApiError(), []),
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}
