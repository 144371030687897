import { AgGridColumn } from "@ag-grid-community/react";
import React, { useEffect, useState } from "react";
import GenericAGGrid from "../../../components/grids/GenericAGGrid";
import { CohorteGroupeService } from "../../../services/api/commonServices";

const GroupEditModal = ({ cohorteId, groupes, visible, close, onGroupesModifiedCb }) => {
  const [label, setLabel] = useState("");

  const addGroupHandler = async (e) => {
    e.preventDefault();
    await CohorteGroupeService(cohorteId)
      .post({ label: label })
      .then((resp) => {
        onGroupesModifiedCb();
        setLabel("");
      })
      .catch((resp) => {});
  };

  const updateGroupHandler = async (params) => {
    await CohorteGroupeService(cohorteId)
      .patch(params.data.id, { [params.colDef.field]: params.newValue })
      .then((resp) => {
        onGroupesModifiedCb();
      })
      .catch((error) => {
        console.log("Error:", error);
        params.node.setData({ ...params.data, [params.colDef.field]: params.oldValue });
      });
  };

  // Reset state when visibilty changes...
  useEffect(() => {
    setLabel("");
  }, [visible, setLabel]);

  const gridOptions = {
    frameworkComponents: { actionsCellRenderer: ActionsCellRenderer },
    context: { cohorteId: cohorteId, onDeleteCb: onGroupesModifiedCb },
  };

  return (
    <div className={`modal ${visible ? "is-active" : ""}`}>
      <div className='modal-background' onClick={() => close()}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Groupes</p>
          <button className='delete' aria-label='close' onClick={() => close()}></button>
        </header>
        <section className='modal-card-body'>
          <div className='field has-addons'>
            <div className='control'>
              <input
                type='text'
                name='group-label-input'
                id='group-label-input'
                className='input'
                value={label}
                placeholder='Nouveau groupe'
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
            <div className='control'>
              <button className='button is-primary' onClick={(e) => addGroupHandler(e)}>
                Ajouter
              </button>
            </div>
          </div>

          {groupes && (
            <>
              <GenericAGGrid data={groupes} height='400px' gridOptions={gridOptions}>
                <AgGridColumn field='label' width={410} headerName='Libellé' menuTabs={[]} sort='asc' editable={true} onCellValueChanged={(params) => updateGroupHandler(params)} />
                <AgGridColumn
                  headerName=''
                  width={185}
                  colId='actions'
                  menuTabs={[]}
                  sortable={false}
                  filter={false}
                  editable={false}
                  cellRenderer='actionsCellRenderer'
                  type='rightAligned'
                />
              </GenericAGGrid>
            </>
          )}
        </section>
        <footer className='modal-card-foot'>
          <button className='button' onClick={() => close()}>
            Fermer
          </button>
        </footer>
      </div>
    </div>
  );
};

const ActionsCellRenderer = (params)=>{
  const deleteGroupHandler = async () => {
    await CohorteGroupeService(params.context.cohorteId)
      .remove(params.data.id)
      .then((resp) => {
        params.context.onDeleteCb();
        // close();
      })
      .catch((resp) => {});
  };

  const editGroupHandler = ()=>{
    params.api.startEditingCell({rowIndex: params.node.rowIndex, colKey: "label"});
  };
  return(<><span className="has-text-primary" onClick={()=>editGroupHandler()}>Modifier</span>&nbsp;&nbsp;&nbsp;<span className="has-text-primary" onClick={()=>deleteGroupHandler()}>Supprimer</span></>)
}

export default GroupEditModal;
