import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EditModal from "./modals/EditModal";

export default function Header({ data }) {
  const history = useHistory();

  let [isEditModalVisible, setEditModalVisibility] = useState(false);
  return (
    <div style={{position: "fixed", top: 0, left: 0, right: 0, zIndex:1000, backgroundColor: "slateblue"}}>
      <div className="level mt-1 mb-2">
        <div className="level-left">
          <div className="level-item">
            <button className="button is-outlined is-small" onClick={history.goBack}>
              &lt;
            </button>
          </div>
          <div className="level-item ml-4">
            <div> 
              <p className="title is-4 has-text-white">{data?.label}</p>
              <p className="has-text-grey-light">
                {data?.demandeur?.name} / {data?.formation?.label}
              </p>
            </div>
          </div>
        </div>
        
      </div>

      <EditModal
        visible={isEditModalVisible}
        close={() => setEditModalVisibility(false)}
      />
    </div>
  );
}
