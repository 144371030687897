import React, { useContext, createContext, useState } from "react";

const authContext = createContext();

export function useAuth() {
  return useContext(authContext);
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useProvideAuth() {
  const [user, setUser] = useState(
    window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user"))
      : null
  );

  const signin = (resp, cb) => {
    setUser(resp);
    window.localStorage.setItem("user", JSON.stringify(resp));
    cb();
  };

  const signout = (cb) => {
    setUser(null);
    window.localStorage.removeItem("user");
    cb();
  };

  return {
    user,
    signin,
    signout,
  };
}
