import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Main from "./Main";
import Login from "./pages/login/login";
import Logout from "./pages/logout/logout";
import { ProvideAuth } from "./utils/use-auth.js";

export default function App() {
  return (
    <ProvideAuth>
      <Router basename='/'>
        <ScrollToTop>
          <Switch>
            <Route path='/login' component={Login} />
            <Route path='/logout' component={Logout} />
            <PrivateRoute component={Main} />
          </Switch>
        </ScrollToTop>
      </Router>
    </ProvideAuth>
  );
}
