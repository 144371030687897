import React, { useEffect, useLayoutEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { CandidatesService, SessionTestsService } from "../../services/api/commonServices";

export default function SeanceDetailsGrid({ data, candidatFilterValue, codePermanentFilterValue, formationFilterValue, etablissementFilterValue }) {
  // eslint-disable-next-line
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);

  useLayoutEffect(() => {
    gridApi?.sizeColumnsToFit();
    window.onresize = () => gridApi.sizeColumnsToFit();
  }, [gridApi]);

  useEffect(() => {
    if (!gridApi) return;
    let instance = gridApi.getFilterInstance("nom_complet");
    instance.setModel({ filterType: "string", type: "contains", filter: candidatFilterValue });
    instance.applyModel();
    gridApi.onFilterChanged();
  }, [candidatFilterValue, data, gridApi]);

  useEffect(() => {
    if (!gridApi) return;
    let instance = gridApi.getFilterInstance("code_permanent");
    instance.setModel({ filterType: "string", type: "contains", filter: codePermanentFilterValue });
    instance.applyModel();
    gridApi.onFilterChanged();
  }, [codePermanentFilterValue, data, gridApi]);

  useEffect(() => {
    if (!gridApi) return;
    let instance = gridApi.getFilterInstance("formation");
    instance.setModel({ filterType: "number", type: "equals", filter: formationFilterValue });
    instance.applyModel();
    gridApi.onFilterChanged();
  }, [formationFilterValue, data, gridApi]);
  
  useEffect(() => {
    if (!gridApi) return;
    let instance = gridApi.getFilterInstance("demandeur");
    instance.setModel({ filterType: "number", type: "equals", filter: etablissementFilterValue });
    instance.applyModel();
    gridApi.onFilterChanged();
  }, [etablissementFilterValue, data, gridApi]);

  const onCandidatValueChanged = (params) => {
    CandidatesService(params.data.id_demandeur)
      .patch(params.data.candidates.id, { [params.colDef.field]: params.newValue })
      .then((resp) => {
        // params.api.flashCells({
        //   rowNodes: [params.node],
        // });
        params.api.onFilterChanged();
      })
      .catch((error) => {
        console.log("Error:", error);
        params.node.setData({ ...params.data, [params.colDef.field]: params.oldValue });
        params.api.onFilterChanged();
      });
  };

  const onSessionTestsValueChanged = (params) => {
    SessionTestsService()
      .patch(params.data.id, { [params.colDef.field]: params.newValue })
      .then((resp) => {
        // params.api.flashCells({
        //   rowNodes: [params.node],
        // });
        params.api.onFilterChanged();
      })
      .catch((error) => {
        console.log("Error:", error);
        params.node.setData({ ...params.data, [params.colDef.field]: params.oldValue });
        params.api.onFilterChanged();
      });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    enableCellChangeFlash: false,
    minWidth: 100,
  };

  const gridOptions = {
    immutableData: true,
    getRowNodeId: (data) => data.id,
    onGridReady: onGridReady,
    sideBar: { toolPanels: ["columns", "filters"] },
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "left",
      },
    ],
  };

  return (
    <div className='ag-theme-balham' style={{ width: "100%", height: 800 }}>
      <AgGridReact rowData={data} gridOptions={gridOptions} modules={[ClientSideRowModelModule, StatusBarModule]} statusBar={statusBar} defaultColDef={defaultColDef}>
        <AgGridColumn headerName='ID Sess. tests' field='id' hide={true} />
        <AgGridColumn headerName='ID Candidat' valueGetter={(params) => params.data.candidates.id} hide={true} />
        <AgGridColumn
          headerName='Candidat'
          field='nom_complet'
          valueGetter={(params) => params.data.candidates.nom_complet}
          valueSetter={(params) => {
            params.data.candidates.nom_complet = params.newValue;
            return params;
          }}
          filterValueGetter={(params) => params.data.candidates.prenom + " " + params.data.candidates.nom + " " + params.data.candidates.prenom} // Prénom is there twice in order to match on both [prenom] [nom] AND [nom] [prenom].
          editable={true}
          onCellValueChanged={onCandidatValueChanged}
          sort='asc'
        />
        <AgGridColumn
          headerName='Code permanent'
          field='code_permanent'
          editable={true}
          onCellValueChanged={onCandidatValueChanged}
          valueGetter={(params) => params.data.candidates.code_permanent}
        />
        <AgGridColumn headerName='Code barres' field='barcode' editable={true} onCellValueChanged={onSessionTestsValueChanged} />
        <AgGridColumn
          headerName='Présence'
          field='statut'
          editable={true}
          cellEditor='agSelectCellEditor'
          cellEditorParams={{ values: ["", "Absent", "Présent"] }}
          onCellValueChanged={onSessionTestsValueChanged}
        />
        <AgGridColumn headerName='Formation' field='formation' valueGetter={(params) => params.data.formation.label} filterValueGetter={(params) => params.data.formation.id} />
        <AgGridColumn headerName='Établissement' field='demandeur' valueGetter={(params) => params.data.demandeur.name} filterValueGetter={(params) => params.data.demandeur.id} />
        <AgGridColumn headerName='Commencée' field='dt_commence' />
        <AgGridColumn headerName='Terminée' field='dt_termine' />
      </AgGridReact>
    </div>
  );
}
