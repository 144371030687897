import React from "react";
import useLocationSearch from "../../utils/use-location-search";
import { useSwrEtablissements } from "../../utils/use-swr";
import GenericSelectFilter from "./GenericSelectFilter";

const EtablissementsFilter = ({ width }) => {
  const { value, setValue } = useLocationSearch('demandeurs');
  const { data, isError, isLoading } = useSwrEtablissements(process.env.REACT_APP_PARTENAIRE ? '?partenaires=' + process.env.REACT_APP_PARTENAIRE : null);

  return <GenericSelectFilter label='Établissement' width={width} isLoading={isLoading} isError={isError} value={value} onChange={setValue} data={data} labelKey='name' />;
};

export default EtablissementsFilter;
