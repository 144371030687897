import _ from "lodash";
import {
  CohortesCandidatsService,
  CandidatesDemandeurService,
  SessionTestsService,
} from "../../../../services/api/commonServices";
import ageValueGetter from "./valueSettersGetters/infoCandidats/age";
import aTraiterValueGetter from "./valueSettersGetters/infoCandidats/aTraiter";
import groupeValueSetter from "./valueSettersGetters/infoCandidats/groupe";
import groupeIdValueSetter from "./valueSettersGetters/infoCandidats/groupeId";
import mesuresAdapteesValueGetter from "./valueSettersGetters/infoCandidats/mesuresAdaptees";
import seanceValueGetter from "./valueSettersGetters/infoCandidats/seance";
import sessionValueGetter from "./valueSettersGetters/infoCandidats/session";
import sessionIdValueGetter from "./valueSettersGetters/infoCandidats/sessionId";

////////////////////////////////////////////
// Event Handlers
////////////////////////////////////////////
const onCandidatValueChanged = (params) => {
  CandidatesDemandeurService(params.data.demandeur_id)
    .patch(params.data.candidat_id, { [params.colDef.field]: params.newValue })
    .then((resp) => {
      params.api.flashCells({
        rowNodes: [params.node],
      });
      params.api.refreshClientSideRowModel();
    })
    .catch((error) => {
      console.log("Error:", error);
      params.node.setData({
        ...params.data,
        [params.colDef.field]: params.oldValue,
      });
    });
};

const onCohorteCandidatCellValueChanged = (params) => {
  console.log("Modif", { [params.colDef.field]: params.newValue });
  CohortesCandidatsService(params.data.cohorte_id)
    .patch(params.data.id, { [params.colDef.field]: params.newValue })
    .then((resp) => {
      params.api.flashCells({
        rowNodes: [params.node],
      });
      params.api.refreshClientSideRowModel();
    })
    .catch((error) => {
      console.log("Error:", error);
      params.node.setData({
        ...params.data,
        [params.colDef.field]: params.oldValue,
      });
    });
};

const onAppointmentCellValueChanged = (params) => {
  console.log("Modif", { [params.colDef.field]: params.newValue });
  SessionTestsService()
    .patch(params.data.id, { [params.colDef.field]: params.newValue })
    .then((resp) => {
      params.api.flashCells({
        rowNodes: [params.node],
      });
      params.api.refreshClientSideRowModel();
    })
    .catch((error) => {
      console.log("Error:", error);
      params.node.setData({
        ...params.data,
        [params.colDef.field]: params.oldValue,
      });
    });
};

////////////////////////////////////////////
// Columns
////////////////////////////////////////////

const infosCandidat = [
  {
    headerName: "ID Candidat",
    field: "candidat_id",
    editable: false,
    columnGroupShow: "open",
    width: 70,
    hide: true,
  },
  {
    headerName: "ID Cohortes-Candidats",
    field: "id",
    editable: false,
    columnGroupShow: "open",
    width: 70,
    hide: true,
  },
  {
    headerName: "Code permanent",
    field: "code_permanent",
    editable: true,
    onCellValueChanged: onCandidatValueChanged,
    columnGroupShow: "null", // Always show
    width: 150,
  },
  {
    headerName: "Courriel",
    field: "courriel",
    columnGroupShow: "open",
    hide: true,
  },
  {
    headerName: "Âge",
    field: "age",
    valueGetter: ageValueGetter,
    headerTooltip: "Âge au moment des tests",
    filter: "agNumberColumnFilter",
    columnGroupShow: "open",
  },
  {
    headerName: "DDN",
    field: "date_naissance",
    columnGroupShow: "open",
    hide: true,
  },
  { headerName: "Sexe", field: "sexe", columnGroupShow: "open" },
  {
    headerName: "À traiter",
    field: "to_be_handled",
    columnGroupShow: "open",
    hide: true,
    valueGetter: aTraiterValueGetter,
  },
  {
    headerName: "M.A.", // Mesures adaptées
    field: "util_sess_adapt",
    valueGetter: mesuresAdapteesValueGetter,
    headerTooltip: "Tests avec mesures adaptées",
    columnGroupShow: "open",
  },
  {
    headerName: "Séances",
    valueGetter: seanceValueGetter,
    columnGroupShow: "open",
    hide: true,
  },
  {
    headerName: "Session",
    valueGetter: sessionValueGetter,
    columnGroupShow: "open",
    hide: true,
  },
  {
    headerName: "ID sess. tests",
    valueGetter: sessionIdValueGetter,
    columnGroupShow: "open",
    hide: true,
  },
  {
    headerName: "Code barres",
    field: "util_sess_barcode",
    columnGroupShow: "open",
    editable: true,
    hide: true,
    onCellValueChanged: onAppointmentCellValueChanged,
    width: 120,
  },
  {
    headerName: "Commencée (Sess. tests)",
    field: "util_sess_dt_commence",
    columnGroupShow: "open",
    hide: true,
    width: 120,
  },
  {
    headerName: "Terminée (Sess. tests)",
    field: "util_sess_dt_termine",
    columnGroupShow: "open",
    hide: true,
    width: 120,
  },
  {
    headerName: "Statut",
    field: "statut",
    width: 100,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: [
        "",
        "Accepté",
        "Refusé",
        "Attente",
        "À l'étude",
        "Abandon",
        "Désisté",
        "Retrait temporaire",
      ],
    },
    editable: true,
    onCellValueChanged: onCohorteCandidatCellValueChanged,
  },
  {
    headerName: "Groupe ID",
    field: "groupe_id",
    hide: true,
    editable: true,
    valueSetter: groupeIdValueSetter,
    onCellValueChanged: onCohorteCandidatCellValueChanged,
  },
  {
    headerName: "Groupe",
    field: "groupe",
    width: 120,
    editable: true,
    filter: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: (params) => {
      return {
        values: [
          "", // Add empty option
          ..._.map(params.context.groupes, "label"),
        ],
      };
    },
    valueSetter: groupeValueSetter,
    // onCellValueChanged: --> event is handled from groupe_id column
  },
];

export default infosCandidat;
