import React, { useState } from "react";
import { getABCDKColsMetadata } from "../columns/columnDefs/abcdk";
import { getAptColsMetadata } from "../columns/columnDefs/aptitudes";
import { getCompColsMetadata } from "../columns/columnDefs/competences";
import createChart from "../modules/overviewCharts/overViewCharts";

const ShowchartsButton = ({ gridRef, cohortData }) => {
  const [colsCompetences, setcolsCompetences] = useState([]);
  const [colsAptitudes, setcolsAptitudes] = useState([]);
  const [colsABCDK, setcolsABCDK] = useState([]);

  const onSelectClick = (e) => {
    setcolsCompetences(getCompColsMetadata());
    setcolsAptitudes(getAptColsMetadata());
    setcolsABCDK(getABCDKColsMetadata());
  };

  // ** Left here as a reference to use ag-grid charts as standalone (ag-charts).
  // const createChartStandalone = (params) => {

  //   let chartData = [];
  //   candidatsCohortes.forEach((cc) => {
  //     let candidateData = {nom_complet: cc.nom_complet, score_percent: undefined};
  //     cc.resultats?.[params.type]?.forEach((result) => {
  //       if (result.interpretation_id === params.interpretation_id) {
  //         candidateData.score_percent = result.score_percent;
  //       }
  //     });
  //     chartData.push(candidateData);
  //   });

  //   // Sort results in descending order
  //   chartData.sort((a, b) => (a.score_percent > b.score_percent) ? -1 : 1);

  //   let chartParams = {
  //     data: chartData,
  //     visible: true,
  //     title: params.title,
  //     type: params.type
  //   }
  //   setGroupViewChartParams(chartParams);
  // }

  return (
    <div className="field">
      <div
        className="control select is-small is-full-width"
        style={{ marginTop: "calc(18px + 0.5rem)" }}
      >
        <select
          onChange={(e) => {
            e.preventDefault();
            // This is the only method I found that works to access data-attributes with react. FT:
            var dataset = e.target.options[e.target.selectedIndex].dataset;

            // Create chart using integrated charts (through grid):
            createChart({
              gridRef: gridRef,
              cohortData: cohortData,
              columnId: e.target.value,
              title: dataset.label,
            });
            // Create chart using AgCharts (standalone library):
            //createChartStandalone({columnId: e.target.value, title: dataset.label, type: dataset.type, interpretation_id: parseInt(dataset.interpretation_id)});

            // Reset to default value (without triggering a change event)
            setTimeout(() => (e.target.selectedIndex = 0), 500);
          }}
          onClick={onSelectClick}
        >
          <option value="">Afficher les graphiques Vue d'ensemble</option>
          <optgroup label="Compétences">
            <Options
              dynamicColMetadata={colsCompetences}
              type={"competences"}
            />
          </optgroup>
          <optgroup label="Aptitudes">
            <Options dynamicColMetadata={colsAptitudes} type={"aptitudes"} />
          </optgroup>
          <optgroup label="ABCDK">
            <Options dynamicColMetadata={colsABCDK} type={"abcdk"} />
          </optgroup>
        </select>
      </div>
    </div>
  );
};

const Options = ({ dynamicColMetadata, type }) => {
  // Iterate over object dynamicColMetadata
  return Object.entries(dynamicColMetadata).map(
    ([key, value], index, dynCols) => {
      return (
        <option
          key={index}
          data-label={value.label}
          data-type={type}
          data-interpretation_id={value.interpretation_id}
          value={value.colId}
        >
          {value.label}
        </option>
      );
    }
  );
};

export default ShowchartsButton;
