import React from "react";

const AppWrapper = ({ children }) => {
  return (
    <div id="app-wrapper" className="">
      {children}
    </div>
  );
};

export default AppWrapper;
