import { useEffect } from "react";
import Swal from "sweetalert2";

const DefaultErrorModal = () => {
  useEffect(() => {
    Swal.fire({
      title: "Oups...",
      text: "Erreur côté serveur",
      icon: "error",
      cancelButtonText: "Fermer",
    });
  });

  return null;
};

export default DefaultErrorModal;
