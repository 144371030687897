import { CandidatesDemandeurService } from "../../../../services/api/commonServices";

const onCandidatValueChanged = (params) => {
  CandidatesDemandeurService(params.data.demandeur_id)
    .patch(params.data.candidat_id, { [params.colDef.field]: params.newValue })
    .then((resp) => {
      params.api.flashCells({
        rowNodes: [params.node],
      });
      params.api.refreshClientSideRowModel();
    })
    .catch((error) => {
      console.log("Error:", error);
      params.node.setData({
        ...params.data,
        [params.colDef.field]: params.oldValue,
      });
    });
};

const candidat = [
  {
    headerName: "Nom, prénom",
    field: "nom_complet",
    colId: "nom_complet",
    sort: "asc",
    pinned: "left",
    editable: true,
    onCellValueChanged: onCandidatValueChanged,
    width: 180,
  },
];

export default candidat;
