import { isRowEnabled } from "../../cohort";

const checkBox = [
  {
    headerName: "",
    field: "checkbox",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    valueGetter: (params) => "",
    filter: false,
    suppressMenu: true,
    resizable: false,
    pinned: "left",
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
      return nodeA.selected === nodeB.selected
        ? 0
        : nodeA.selected < nodeB.selected
        ? 1
        : -1;
    },
    // This 'agGroupCellRenderer' displays icon to expand or collaspe the detail view
    cellRendererSelector: (params) => {
      if (params?.context?.authRef?.user?.type_utilisateur >= 5) {
        return {
          component: "agGroupCellRenderer",
          params: {
            // optional parameters
            checkbox: function (params) {
              params.node.selectable = isRowEnabled(params) ? true : false;
            },
          },
        };
      } else {
        return null;
      }
    },
  },
];

export default checkBox;
