import React from "react";
import _ from "lodash";
import { CohortTransferService } from "../../../services/api/cohortTranferService";

const TransferCandidate = (props) => {
  const {
    selectedRows,
    cohortId,
    gridRef,
    transferedCB,
    cohortAvailForTransfer,
  } = props;

  const assignSelectionToCohorteClicked = (
    currentCohortId,
    cohortIdTransferTo
  ) => {
    gridRef.current.api.showLoadingOverlay();

    CohortTransferService(currentCohortId)
      .transfer({
        ids: _.map(selectedRows, "id").join(),
        cohorte_id: cohortIdTransferTo,
      })
      .then(() => {
        gridRef.current.api.deselectAll();
        gridRef.current.api.hideOverlay();
        if (transferedCB) transferedCB();
      });
  };

  const onChange = (e) => {
    const cohortIdTransferTo = e.target.value;
    const cohortIdcurrent = cohortId;
    assignSelectionToCohorteClicked(cohortIdcurrent, cohortIdTransferTo);
    // Reset to default value (without triggering a change event)
    setTimeout(() => (e.target.selectedIndex = 0), 500);
  };

  return (
    <div className="select is-small is-outlined">
      <select
        defaultValue={""}
        onChange={onChange}
        disabled={!selectedRows?.length || !cohortAvailForTransfer?.length}
      >
        <option value="" disabled>
          Transférer la sélection
        </option>
        {cohortAvailForTransfer?.map((item) => {
          return (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default TransferCandidate;
