import MiniBarChartCellRenderer from "./cellRenderers/common/MiniBarChartCellRenderer";
import { getUniqueColsFromRowData } from "./helpers/dynamicCols";
import barChartValueGetter from "./valueSettersGetters/aptitudes/barChart";
import cellStyleValueGetter from "./valueSettersGetters/aptitudes/cellStyle";
import moyenneValueGetter from "./valueSettersGetters/aptitudes/moyenne";
import scorePercentValueGetter from "./valueSettersGetters/aptitudes/scorePercent";

const baseColDefs = [
  {
    headerName: "Moy.",
    columnGroupShow: null,
    filter: false,
    width: 70,
    headerTooltip: "Moyenne des scores aptitudes",
    valueGetter: moyenneValueGetter,
  },
  {
    headerName: "",
    columnGroupShow: "closed",
    width: 100,
    filter: false,
    valueGetter: barChartValueGetter,
    cellRendererFramework: MiniBarChartCellRenderer,
    cellRendererParams: { id: "aptitudes-mini-chart" },
  },
];

// Here 'dynamic' refers to the fact that
// the columns are retreived from the Grid data
const getDynColDefs = (apts) => {
  return apts?.map((apt) => ({
    key: apt.colId,
    colId: apt.colId,
    headerName: apt.label ? apt.label.substring(0, 3) : apt.label,
    label: apt.label,
    headerTooltip: apt.label,
    columnGroupShow: "open",
    type: "numericColumn",
    chartDataType: "series",
    valueGetter: (params) => scorePercentValueGetter(params, apt),
    cellStyle: cellStyleValueGetter,
    filter: false,
  }));
};

// Adds aptitude columns to the grid dynamically
let uniqueAptCols = [];
export const addAptitudesColumns = (gridRef) => {
  // Get aptitudes from rowData
  uniqueAptCols = getUniqueColsFromRowData(gridRef.current.props.rowData, "aptitudes", "apt_");

  // If none, stop here
  if (uniqueAptCols.length === 0) return;
  
  // Build colDefs array for each aptitude
  getDynColDefs(uniqueAptCols);

  // Get current grid column definitions
  let gridColDefs = gridRef.current.api.getColumnDefs();

  // Append aptitudes colDefs to the grid's colDefs
  gridColDefs.push({
    headerName: "Aptitudes",
    groupId: "aptitudes",
    suppressColumnsToolPanel: true,
    children: [...baseColDefs, ...getDynColDefs(uniqueAptCols)],
    filter: false,
  });

  // Set new colDefs back to grid
  gridRef.current.api.setColumnDefs(gridColDefs);
};

export const getAptColsMetadata = () => uniqueAptCols;
