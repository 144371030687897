import React from "react";

const DownloadXLSButton = ({ gridRef }) => {
  return (
    <button
      className="button is-small is-outlined"
      onClick={() => gridRef.current.api.exportDataAsExcel()}
    >
      Télécharger .xls
    </button>
  );
};

export default DownloadXLSButton;
