import React from "react";
import { useSwrSeances } from "../../utils/use-swr";
import { useHistory, useParams } from "react-router-dom";
import ListFilters from "../../components/ListFilters";
import SeanceDetailsGrid from "./seanceDetailsGrid";
import FormationsFilter from "../../components/filters/FormationsFilter";
import EtablissementsFilter from "../../components/filters/EtablissementsFilter";
import CandidatsFilter from "../../components/filters/CandidatsFilter";
import CodePermanentFilter from "../../components/filters/CodePermanentFilter";
import queryString from "query-string";

export default function SeanceDetails(props) {
  const history = useHistory();
  const { id } = useParams();
  const { location } = props;

  const candidatFilterValue = queryString.parse(location?.search)["candidat"];
  const codePermanentFilterValue = queryString.parse(location?.search)["code_permanent"];
  const formationFilterValue = queryString.parse(location?.search)["formations"];
  const etablissementFilterValue = queryString.parse(location?.search)["demandeurs"];
  const { data, isError } = useSwrSeances(null, id);

  if (isError) return <p>Erreur: {isError.statusText}</p>;

  return (
    <>
      <div className='section'>
        <div id='resultats' className='container'>
          <div className='level'>
            <div className='level-left'>
              <div className='level-item'>
                <button className='button is-outlined is-small' onClick={history.goBack}>
                  &lt;
                </button>
              </div>
              <div className='level-item ml-4'>
                <p className='title is-3'>{data?.label}</p>
              </div>
            </div>
            <div className='level-right'>
              <div className='level-item'>
                <div>
                  <p className='has-text-grey-light has-text-right'>{data?.dt_debut}</p>
                  <p className='has-text-grey-light has-text-right'>{data?.dt_fin}</p>
                </div>
              </div>
            </div>
          </div>
          <ListFilters>
            <CandidatsFilter width='3' />
            <CodePermanentFilter width='3' />
            <EtablissementsFilter width='3' />
            <FormationsFilter width='3' />
          </ListFilters>
          <SeanceDetailsGrid
            data={data?.utilisateur_session}
            candidatFilterValue={candidatFilterValue}
            codePermanentFilterValue={codePermanentFilterValue}
            formationFilterValue={formationFilterValue}
            etablissementFilterValue={etablissementFilterValue}
          />
        </div>
      </div>
    </>
  );
}
