const selectedOverTotalValueGetter = (params)=>{
  let selected = params.api.getSelectedRows().length;
  // Note: getDisplayedRowCount() returns total number of rows in all pages, plus all expanded rows
  // which is not what we want. Instead, use forEachNodeAfterFilter()
  //let total = params.api.getDisplayedRowCount();
  let total=0;
  params.api.forEachNodeAfterFilter((rowNode, index) => { total++});

  if(selected){
    return selected + "/" + total;
  }

  return total ? total : "";

}

export default selectedOverTotalValueGetter;