////////////////////////////////////////////
// Event Handlers

import { CohortesCandidatsService } from "../../../../services/api/commonServices";

////////////////////////////////////////////
const onCohorteCandidatCellValueChanged = (params) => {
  CohortesCandidatsService(params.data.cohorte_id)
    .patch(params.data.id, { [params.colDef.field]: params.newValue })
    .then((resp) => {
      params.api.flashCells({
        rowNodes: [params.node],
      });
      params.api.refreshClientSideRowModel();
    })
    .catch((error) => {
      console.log("Error:", error);
      params.node.setData({
        ...params.data,
        [params.colDef.field]: params.oldValue,
      });
    });
};

const commentaire = [
  {
    headerName: "Commentaire",
    field: "commentaire",
    flex: 1,
    minWidth: 100,
    editable: true,
    // suppressSizeToFit for "flex: 1" to work
    suppressSizeToFit: false,
    columnGroupShow: "open",
    pinned: 'right',
    onCellValueChanged: onCohorteCandidatCellValueChanged,
  },
  {
    headerName: "C",
    field: "commentaire",
    minWidth: 100,
    columnGroupShow: "closed",
    editable: false,
    pinned: 'right',
  },
];

export default commentaire;
