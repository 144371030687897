import React from 'react';

export default function PistesInterventions({ data }) {
  const Row = ({ piste }) => {
    return (
      <>
        <tr>
          <td>{piste.titre}</td>
          <td>{(piste.interpretation_text_fr) ? piste.interpretation_text_fr : piste.definition}</td>
        </tr>
      </>
    );
  };

  return (
    <div className='container'>
      <div className='columns is-centered'>
        <div className='column is-narrow'>
          <div className='table-container is-centered'>
            <table className='table is-bordered is-striped is-centered'>
              <tbody>
                {data &&
                  data.map((piste) => {
                    return <Row piste={piste} key={piste.id} />;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
