import React from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/api/authService";
import { useAuth } from "../../utils/use-auth.js";

export default function Logout() {
  let auth = useAuth();
  let history = useHistory();

  AuthService.logout();

  if (auth.user) {
    auth.signout(() => {
      history.push("/login");
    });
  } else {
    history.push("/login");
  }

  return <></>;
}
