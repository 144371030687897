import React, { useEffect, useLayoutEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import moment from "moment";
import localization from "moment/locale/fr";
import { CohortesCandidatsService, CandidatesDemandeurService, SessionTestsService } from "../../services/api/commonServices";
import _ from "lodash";
import { useAuth } from "../../utils/use-auth";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import DetailCellRenderer from "./detailCellRenderer";
import MiniBarChartCellRenderer from "./cellRenderers/MiniBarChartCellRenderer";
import CustomFiltersToolPanel from "./customPanels/customFiltersToolPanel";

export default function CohortDetailsGrid({
  data,
  groupes,
  mutate,
  setGridParamsCb,
  statutFilterValue,
  groupeFilterValue,
  onSelectionChangedCb,
  competencesColumns,
  aptitudesColumns,
  abcdkColumns,
}) {
  moment().locale("fr", localization);

  const auth = useAuth();

  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const detailRowHeight = 350;

  const baseColors = {
    red: "rgb(192, 0, 0)",
    yellow: "rgb(255, 192, 0)",
    green: "rgb(112, 173, 71)",
  };

  const aptitudesColorRulesByInterpretationId = {
    112: {
      [baseColors.red]: { min: 0, max: 29 },
      [baseColors.yellow]: { min: 30, max: 49 },
      [baseColors.green]: { min: 80, max: 100 },
    },
    113: {
      [baseColors.red]: { min: 0, max: 29 },
      [baseColors.yellow]: { min: 30, max: 49 },
      [baseColors.green]: { min: 80, max: 100 },
    },
    114: {
      [baseColors.red]: { min: 0, max: 25 },
      [baseColors.yellow]: { min: 26, max: 45 },
      [baseColors.green]: { min: 80, max: 100 },
    },
    115: {
      [baseColors.red]: { min: 0, max: 29 },
      [baseColors.yellow]: { min: 30, max: 49 },
      [baseColors.green]: { min: 80, max: 100 },
    },
    116: {
      [baseColors.red]: { min: 0, max: 49 },
      [baseColors.yellow]: { min: 50, max: 69 },
      [baseColors.green]: { min: 90, max: 100 },
    },
    105: {
      [baseColors.red]: { min: 0, max: 49 },
      [baseColors.yellow]: { min: 50, max: 65 },
      [baseColors.green]: { min: 80, max: 100 },
    },
    106: {
      [baseColors.red]: { min: 0, max: 30 },
      [baseColors.yellow]: { min: 31, max: 40 },
      [baseColors.green]: { min: 70, max: 100 },
    },
    108: {
      [baseColors.red]: { min: 0, max: 30 },
      [baseColors.yellow]: { min: 31, max: 40 },
      [baseColors.green]: { min: 70, max: 100 },
    },
    110: {
      [baseColors.red]: { min: 0, max: 30 },
      [baseColors.yellow]: { min: 31, max: 40 },
      [baseColors.green]: { min: 70, max: 100 },
    },
    111: {
      [baseColors.red]: { min: 0, max: 25 },
      [baseColors.yellow]: { min: 26, max: 39 },
      [baseColors.green]: { min: 70, max: 100 },
    },
  };

  const abcdkBaseColors = {
    yellow: { bgColor: "rgb(255, 229, 152)", fontWeight: "normal" },
    yellowBold: { bgColor: "rgb(255, 229, 152)", fontWeight: "bold" },
    green: { bgColor: "rgb(168, 208, 141)", fontWeight: "normal" },
    greenBold: { bgColor: "rgb(168, 208, 141)", fontWeight: "bold" },
    blue: { bgColor: "rgb(156, 194, 229)", fontWeight: "normal" },
    blueBold: { bgColor: "rgb(156, 194, 229)", fontWeight: "bold" },
    red: { bgColor: "rgb(255, 133, 135)", fontWeight: "normal" },
    redBold: { bgColor: "rgb(255, 133, 135)", fontWeight: "bold" },
    orange: { bgColor: "rgb(244, 176, 131)", fontWeight: "normal" },
    orangeBold: { bgColor: "rgb(244, 176, 131)", fontWeight: "bold" },
  };

  const abcdkColorRulesByInterpretationId = {
    // Analytique
    163: {
      yellow: { min: 45, max: 69 },
      yellowBold: { min: 70, max: 100 },
    },
    // Pragmatique
    164: {
      green: { min: 45, max: 69 },
      greenBold: { min: 70, max: 100 },
    },
    // Emotivo-Relationnel
    165: {
      blue: { min: 45, max: 69 },
      blueBold: { min: 70, max: 100 },
    },
    // Visuo-Imaginatif
    166: {
      red: { min: 45, max: 69 },
      redBold: { min: 70, max: 100 },
    },
    // Kinesthesique
    162: {
      orange: { min: 45, max: 69 },
      orangeBold: { min: 70, max: 100 },
    },
  };

  const cellClassRulesCompetences = {
    "rag-red": "x !== null && x >=0 && x <= 55",
    "rag-green": "x !== null && x >= 80 && x <= 100",
  };

  const cellStyleAptitudes = (params) => {
    let interpretation_id = params.data.resultats?.aptitudes?.find((apt) => {
      return apt.label === params.colDef.label;
    })?.interpretation_id;
    if (interpretation_id === undefined || params.value === null || !aptitudesColorRulesByInterpretationId.hasOwnProperty(interpretation_id)) {
      return null;
    }
    let color = Object.keys(aptitudesColorRulesByInterpretationId[interpretation_id]).find(
      (key) =>
        params.value >= aptitudesColorRulesByInterpretationId[interpretation_id][key].min && params.value <= aptitudesColorRulesByInterpretationId[interpretation_id][key].max,
    );
    if (color !== undefined) {
      return { color: color, "font-weight": "bold" };
    }
  };
  const cellStyleABCDK = (params) => {
    let interpretation_id = params.data.resultats?.abcdk?.find((abcdk) => {
      return abcdk.label === params.colDef.label;
    })?.interpretation_id;
    if (interpretation_id === undefined || params.value === null || !abcdkColorRulesByInterpretationId.hasOwnProperty(interpretation_id)) {
      return null;
    }
    let style = Object.keys(abcdkColorRulesByInterpretationId[interpretation_id]).find(
      (key) => params.value >= abcdkColorRulesByInterpretationId[interpretation_id][key].min && params.value <= abcdkColorRulesByInterpretationId[interpretation_id][key].max,
    );
    if (style !== undefined) {
      if (abcdkBaseColors[style] !== undefined) {
        return { backgroundColor: abcdkBaseColors[style].bgColor, "font-weight": abcdkBaseColors[style].fontWeight };
      }
    }
  };

  let dataWasLoaded = React.useRef(false);
  useEffect(() => {
    if (!gridApi || !data) return;    
    console.log(dataWasLoaded.current);
    console.log(data);
    if (dataWasLoaded.current) {
      console.log("applying grid state from dataWasLoaded is true...");
      applyGridState({api: gridApi, columnApi: gridColumnApi}); 
    } else {
      gridApi.context.groupes = groupes;
      console.log("setting data from useEffect... again");
      gridApi.setRowData(data);    
      dataWasLoaded.current = true;
    }    
  }, [groupes, gridApi, data, gridColumnApi]);

  useLayoutEffect(() => {
    window.onresize = () => gridApi.sizeColumnsToFit();
  }, [gridApi]);

  // Cleanup event... the react way
  useLayoutEffect(() => {
    return () => {
      window.onresize = null;
      localStorage.removeItem('ag-grid-persistane-columns');
      localStorage.removeItem('ag-grid-persistane-filtering');
      localStorage.removeItem('ag-grid-persistane-grouping');
    }
}, [])

  const defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    enableCellChangeFlash: false,
    minWidth: 100,
    filterParams: {newRowsAction: 'keep'}
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridParamsCb(params);

    // TODO: put this where needed, not working here at new page load
    //params.api.setRowData(data);    
    //applyGridState(params);

    //Debug: columns order - Try with and without applyColumnDefOrder={true}
    // var cols = params.columnApi.getAllColumns();
    // cols.forEach(function (col) {
    //   var colDef = col.getUserProvidedColDef();
    //   console.log(
    //     colDef.headerName + ', Column ID = ' + col.getId(),
    //     JSON.stringify(colDef)
    //   );
    // });
  };

  const onRowDataChanged = (params) => {
    //applyGridState(params);   
  }

  const applyGridState = (params) => {
    
    let columnState = localStorage.getItem('ag-grid-persistane-columns');
    if (columnState) {
      params.columnApi.setColumnState(JSON.parse(columnState));
    }

    let filterModel = localStorage.getItem('ag-grid-persistane-filtering');    
    if (filterModel) {
      params.api.setFilterModel(JSON.parse(filterModel));
    }

    let columnGroupState = localStorage.getItem('ag-grid-persistane-grouping');
    if (columnGroupState) {
      params.columnApi.setColumnGroupState(JSON.parse(columnGroupState));
    }
    
    params.api.sizeColumnsToFit();    
  }

  const onFirstDataRendered = (params) => {
    console.log("applying grid state from onFirstDataRendered...");
    applyGridState(params); 
  };

  const onSortChanged = (params) => {        
    console.log("sorting has changed");
    //localStorage.setItem('ag-grid-persistane-sorting', JSON.stringify(params.api.getSortModel()));
    localStorage.setItem('ag-grid-persistane-columns', JSON.stringify(params.columnApi.getColumnState()));  
  }
  const onFilterChanged = (params) => {    
    localStorage.setItem('ag-grid-persistane-filtering', JSON.stringify(params.api.getFilterModel()));
    // localStorage.setItem('ag-grid-persistane-grouping', JSON.stringify(params.columnApi.getColumnGroupState()));
    // localStorage.setItem('ag-grid-persistane-columns', JSON.stringify(params.columnApi.getColumnState()));
  }
  const onColumnStateChanged = (params) => {
    if (!params.gridColumnApi) return;
    // From ag-grid blog:
    // let columnState = this.gridColumnApi?.getColumnState();
    // let columnGroupState = this.gridColumnApi?.getColumnGroupState();
    // this.debounceSaveGridColumnState(this.props.id, columnState);
    // this.debounceSaveGridColumnGroupState(this.props.id, columnGroupState);

    console.log("onColumnStateChanged");

    // My implementation:
    localStorage.setItem('ag-grid-persistane-grouping', JSON.stringify(params.columnApi.getColumnGroupState()));
    localStorage.setItem('ag-grid-persistane-columns', JSON.stringify(params.columnApi.getColumnState()));
  }

  const onCandidatValueChanged = (params) => {
    CandidatesDemandeurService(params.data.demandeur_id)
      .patch(params.data.candidat_id, { [params.colDef.field]: params.newValue })
      .then((resp) => {
        // params.api.flashCells({
        //   rowNodes: [params.node],
        // });
        //params.api.onFilterChanged();

        console.log("applying grid state from onCandidatValueChanged...");
        applyGridState(params);   
      })
      .catch((error) => {
        console.log("Error:", error);
        params.node.setData({ ...params.data, [params.colDef.field]: params.oldValue });
        //.api.onFilterChanged();
      });
  };
  const onCohorteCandidatCellValueChanged = (params) => {
    CohortesCandidatsService(params.data.cohorte_id)
      .patch(params.data.id, { [params.colDef.field]: params.newValue })
      .then((resp) => {
        //mutate();
        // params.api.flashCells({
        //   rowNodes: [params.node],
        // });
        //params.api.onFilterChanged();

        console.log("applying grid state from onCohorteCandidatCellValueChanged...");
        applyGridState(params);   
      })
      .catch((error) => {
        console.log("Error:", error);
        params.node.setData({ ...params.data, [params.colDef.field]: params.oldValue });
        //params.api.onFilterChanged();
      });
  };

  const onAppointmentCellValueChanged = (params) => {
    console.log(params.colDef.field, params.newvalue);
    SessionTestsService()
      .patch(params.data.util_sess_id, { [params.colDef.field]: params.newValue })
      .then((resp) => {
        //mutate();
        // params.api.flashCells({
        //   rowNodes: [params.node],
        // });
        //params.api.onFilterChanged();

        console.log("applying grid state from onAppointmentCellValueChanged...");
        applyGridState(params);   
      })
      .catch((error) => {
        console.log("Error:", error);
        params.node.setData({ ...params.data, [params.colDef.field]: params.oldValue });
        //params.api.onFilterChanged();
      });
  };

  const onSelectionChanged = (params) => {
    if (onSelectionChangedCb) {
      onSelectionChangedCb(params.api.getSelectedRows());

      console.log("applying grid state from onSelectionChanged...");
      applyGridState(params);   
    }
  };

  const onColumnGroupOpened = (params) => {
    const colGroupsCloseOthers = ["competences", "aptitudes", "abcdk"];
    // if more than one group is opened, do nothing, it would be counter-productive
    let otherOpenedGroups = false;
    colGroupsCloseOthers.forEach((colGroup) => {
      if (colGroup !== params.columnGroup.groupId && params.columnApi.getColumnGroup(colGroup)?.originalColumnGroup.expanded) {
        otherOpenedGroups = true;
      }
    });
    if (otherOpenedGroups) {
      return;
    }
    if (colGroupsCloseOthers.indexOf(params.columnGroup.groupId) > -1) {
      params.columnApi.setColumnGroupOpened("infoCandidats", !params.columnGroup.expanded);
    }
  };

  const gridOptions = {
    immutableData: true,
    getRowNodeId: (data) => data.id,
    enableCharts: true,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Colonnes",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "customFilters",
          labelDefault: "Filtres",
          iconKey: "filter",
          labelKey: "customFilters",
          toolPanel: "customFiltersToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filtres avancés",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "customFilters",
      position: "left",
    },
    getRowClass: (params) => {
      if (params.data?.transferred_at) {
        return "has-text-grey-light";
      }
    },
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "left",
      },
    ],
  };

  return (
    <div className='ag-theme-balham' style={{ position: "absolute", top: "108px", left: 0, right: 0, bottom: 0 }}>
      <AgGridReact
        gridOptions={gridOptions}
        modules={[ClientSideRowModelModule, MasterDetailModule, MenuModule, ColumnsToolPanelModule, FiltersToolPanelModule, ExcelExportModule, StatusBarModule, GridChartsModule]}
        statusBar={statusBar}
        masterDetail={true}
        keepDetailRows={true}
        stopEditingWhenGridLosesFocus={true}
        detailRowHeight={detailRowHeight}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}        
        onRowDataChanged={onRowDataChanged}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        onColumnVisible={onColumnStateChanged}
        onDisplayedColumnsChanged={onColumnStateChanged}
        onColumnPinned={onColumnStateChanged}
        onColumnResized={onColumnStateChanged}
        onColumnMoved={onColumnStateChanged}
        onColumnRowGroupChanged={onColumnStateChanged}
        onColumnValueChanged={onColumnStateChanged} // A value column was added or removed.
        onColumnPivotChanged={onColumnStateChanged} // A pivot column was added, removed or order changed.
        defaultColDef={defaultColDef}
        //rowData={data}
        enableCellTextSelection={true}
        onSelectionChanged={onSelectionChanged}
        onColumnGroupOpened={onColumnGroupOpened}
        detailCellRendererFramework={DetailCellRenderer}
        accentedSort={true}
        frameworkComponents={{ customFiltersToolPanel: CustomFiltersToolPanel }}
        applyColumnDefOrder={
          true /* To respect order when adding columns programmatically. See: https://www.ag-grid.com/react-grid/column-updating-definitions/#applying-column-order */
        }>
        <AgGridColumn headerName=''>
          <AgGridColumn
            cellRenderer={auth.user?.type_utilisateur >= 5 ? "agGroupCellRenderer" : null}
            cellRendererParams={{
              checkbox: function (params) {
                const node = params.node;
                const isGroupRow = node.level === 0; //only show the checkbox on group row.

                if (isGroupRow) {
                  params.node.selectable = params.data.transferred_at ? false : true; //your condition whether the rendered checkbox is selectable or not
                }

                return isGroupRow;
              },
            }}
            pinned='left'
            sortable={false}
            lockPosition={true}
            menuTabs={[]}
            checkboxSelection={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            valueGetter={(params) => ""}
            minWidth={65}
            width={65}
          />
          <AgGridColumn hide={true} headerName='ID' field='id' filter={true} minWidth={70} />
          <AgGridColumn hide={true} headerName='ID Candidat' field='candidat_id' filter={true} minWidth={70} />
          <AgGridColumn
            headerName='Candidat'
            field='nom_complet'
            filterValueGetter={(params) => params.data.prenom + " " + params.data.nom + " " + params.data.prenom} // Prénom is there twice in order to match on both [prenom] [nom] AND [nom] [prenom].
            filter={true}
            editable={true}
            onCellValueChanged={onCandidatValueChanged}
            minWidth={150}
            width={150}
            sort='asc'
            pinned='left'
            chartDataType='category'
          />
        </AgGridColumn>

        <AgGridColumn headerName='Infos candidat' groupId='infoCandidats' openByDefault={true}>
          <AgGridColumn
            id='code_permanent'
            headerName='Code permanent'
            headerTooltip='Code permanent'
            columnGroupShow='null'
            minWidth={120}
            width={120}
            field='code_permanent'
            tooltipValueGetter={(params) => "Code permanent"}
            editable={true}
            onCellValueChanged={onCandidatValueChanged}
          />
          <AgGridColumn headerName='Courriel' field='courriel' minWidth={70} width={70} columnGroupShow='open' hide={true} />
          <AgGridColumn
            headerName='Âge'
            columnGroupShow='open'
            minWidth={65}
            width={65}
            tooltipValueGetter={(params) => {
              return "Âge au moment des tests";
            }}
            valueGetter={(params) => {
              if (!params.data.date_naissance) {
                return null;
              }
              if (params.data.util_sess_dt_termine) {
                return moment(params.data.util_sess_dt_termine).diff(params.data.date_naissance, "years");
              }
              return moment(params.data.dt_ajout).diff(params.data.date_naissance, "years");
            }}
            headerTooltip='Âge au moment des tests'
            chartDataType='excluded'
          />
          <AgGridColumn headerName='DDN' field='date_naissance' minWidth={70} width={70} columnGroupShow='open' hide={true} />
          <AgGridColumn headerName='Sexe' field='sexe' minWidth={70} width={70} columnGroupShow='open' />
          <AgGridColumn
            field='to_be_handled'
            headerName='À traiter'
            minWidth={70}
            width={70}
            hide={true}
            valueGetter={(params) => (params.data.to_be_handled === 1 ? "Oui" : "")}
          />
          <AgGridColumn
            headerName='MA'
            headerTooltip='Mesures adaptées'
            field='util_sess_adapt'
            minWidth={70}
            width={70}
            columnGroupShow='open'
            valueGetter={(params) => (params.data.util_sess_adapt === 1 ? "Oui" : "")}
          />
          <AgGridColumn headerName='Séance' valueGetter={(params) => params.data.seance_label} hide={true} />
          <AgGridColumn headerName='Session' valueGetter={(params) => params.data.session_nom} hide={true} />
          <AgGridColumn headerName='ID sess. tests' valueGetter={(params) => params.data.util_sess_id} hide={true} />
          <AgGridColumn
            field='barcode'
            headerName='Code barres'
            valueGetter={(params) => params.data.util_sess_barcode}
            valueSetter={(params) => (params.data.util_sess_barcode = params.newValue)}
            hide={true}
            editable={(params) => (params.data.util_sess_id ? true : false)}
            onCellValueChanged={onAppointmentCellValueChanged}
          />
          <AgGridColumn headerName='Commencée (Sess. tests)' valueGetter={(params) => params.data.util_sess_dt_commence} hide={true} />
          <AgGridColumn headerName='Terminée (Sess. tests)' valueGetter={(params) => params.data.util_sess_dt_termine} hide={true} />
          <AgGridColumn
            headerName='Statut'
            columnGroupShow='open'
            minWidth={100}
            width={100}
            field='statut'
            filter={true}
            cellEditor='agSelectCellEditor'
            cellEditorParams={{ values: ["", "Accepté", "Refusé", "Attente", "À l'étude", "Abandon", "Désisté", "Retrait temporaire"] }}
            singleClickEdit={true}
            editable={true}
            onCellValueChanged={onCohorteCandidatCellValueChanged}
          />
          <AgGridColumn headerName='ID Groupe' field='groupe_id' hide={true} />
          <AgGridColumn
            headerName='Groupe'
            columnGroupShow='open'
            field='groupe'
            minWidth={100}
            width={100}
            cellEditor='agSelectCellEditor'
            singleClickEdit={true}
            editable={true}
            filter={true}
            filterValueGetter={(params) => params.data.groupe_id}
            cellEditorParams={(params) => {
              let labels = _.map(groupes, "label");
              labels.unshift(""); // Add empty option
              return { values: labels };
            }}
            valueSetter={(params) => {
              //  When we select a value from our drop down list, this function will make sure
              //  that our row's record receives the "id" (not the text value) of the chosen selection.
              let id = groupes.find((refData) => refData.label === params.newValue)?.id;
              params.data.groupe_id = id ? id : null;
              params.data.groupe = params.newValue;
              return true;
            }}
            onCellValueChanged={(params) => {
              CohortesCandidatsService(params.data.cohorte_id)
                .patch(params.data.id, { groupe_id: params.data.groupe_id })
                .then((resp) => {
                  // TODO : Display toast
                  //params.api.onFilterChanged();
                })
                .catch((error) => {
                  console.log("Error:", error);
                  // TODO: Handle this...
                });
            }}
          />
        </AgGridColumn>
        {auth.user?.type_utilisateur >= 4 && (
          <AgGridColumn headerName='Compétences' groupId='competences'>
            <AgGridColumn
              columnGroupShow='closed'
              headerName='Moy.'
              minWidth={70}
              width={70}
              tooltipValueGetter={(params) => "Moyenne des scores compétences"}
              valueGetter={(params) => {
                let competences = params.data.resultats?.competences;
                let total = 0;
                competences?.forEach((comp) => (total += Number(comp.score_percent)));
                let nbItems = competences?.length ? competences?.length : 1;
                return Math.round(total / nbItems) ? Math.round(total / nbItems) : null;
              }}
            />
            <AgGridColumn
              columnGroupShow='closed'
              editable={false}
              filter={false}
              minWidth={150}
              width={150}
              valueGetter={(params) => {
                return params.data.resultats?.competences;
              }}
              cellRendererFramework={MiniBarChartCellRenderer}
              cellRendererParams={{ id: "competences-mini-chart" }}
            />
            {/* Create columns from state variable 'competencesColumns' */}
            <AgGridColumn
              headerName='Moy.'
              minWidth={70}
              width={70}
              columnGroupShow='open'
              tooltipValueGetter={(params) => "Moyenne des scores compétences"}
              valueGetter={(params) => {
                let competences = params.data.resultats?.competences;
                let total = 0;
                competences?.forEach((comp) => (total += Number(comp.score_percent)));
                let nbItems = competences?.length ? competences?.length : 1;
                return Math.round(total / nbItems) ? Math.round(total / nbItems) : null;
              }}
            />
            {competencesColumns?.map((column) => (
              <AgGridColumn
                {...column}
                flex={1}
                key={column.colId}
                headerName={column.label ? column.label.substring(0, 3) : column.label}
                headerTooltip={column.label}
                columnGroupShow='open'
                type='numericColumn'
                chartDataType='series'
                valueGetter={(params) => {
                  let score_percent = params.data.resultats?.competences?.find((comp) => {
                    return comp.label === column.label;
                  })?.score_percent;
                  return (score_percent === null && params.data.util_sess_dt_termine !== null ? 0 : score_percent);
                }}
                cellClassRules={cellClassRulesCompetences}
              />
            ))}
          </AgGridColumn>
        )}

        {auth.user?.type_utilisateur >= 4 && (
          <AgGridColumn headerName='Aptitudes' groupId='aptitudes'>
            <AgGridColumn
              columnGroupShow='closed'
              headerName='Moy.'
              minWidth={70}
              width={70}
              tooltipValueGetter={(params) => "Moyenne des scores aptitudes"}
              valueGetter={(params) => {
                let aptitudes = params.data.resultats?.aptitudes;
                let total = 0;
                aptitudes?.forEach((apt) => (total += Number(apt.score_percent)));
                let nbItems = aptitudes?.length ? aptitudes?.length : 1;
                return Math.round(total / nbItems) ? Math.round(total / nbItems) : null;
              }}
            />
            <AgGridColumn
              columnGroupShow='closed'
              editable={false}
              filter={false}
              valueGetter={(params) => {
                return params.data.resultats?.aptitudes;
              }}
              cellRendererFramework={MiniBarChartCellRenderer}
              cellRendererParams={{ id: "aptitudes-mini-chart" }}
            />
            <AgGridColumn
              columnGroupShow='open'
              headerName='Moy.'
              minWidth={70}
              width={70}
              tooltipValueGetter={(params) => "Moyenne des scores aptitudes"}
              valueGetter={(params) => {
                let aptitudes = params.data.resultats?.aptitudes;
                let total = 0;
                aptitudes?.forEach((apt) => (total += Number(apt.score_percent)));
                let nbItems = aptitudes?.length ? aptitudes?.length : 1;
                return Math.round(total / nbItems) ? Math.round(total / nbItems) : null;
              }}
            />
            {/* Create columns from state variable 'aptitudesColumns' */}
            {aptitudesColumns?.map((column) => (
              <AgGridColumn
                {...column}
                flex={1}
                key={column.colId}
                headerName={column.label ? column.label.substring(0, 3) : column.label}
                headerTooltip={column.label}
                columnGroupShow='open'
                type='numericColumn'
                chartDataType='series'
                valueGetter={(params) => {
                  let score_percent = params.data.resultats?.aptitudes?.find((apt) => {
                    return apt.label === column.label;
                  })?.score_percent;
                  return (score_percent === null && params.data.util_sess_dt_termine !== null ? 0 : score_percent);
                }}
                cellStyle={cellStyleAptitudes}
              />
            ))}
          </AgGridColumn>
        )}

        {auth.user?.type_utilisateur >= 4 && (
          <AgGridColumn headerName='ABCDK' groupId='abcdk'>
            <AgGridColumn
              columnGroupShow='closed'
              editable={false}
              filter={false}
              valueGetter={(params) => {
                return params.data.resultats?.abcdk;
              }}
              cellRendererFramework={MiniBarChartCellRenderer}
              cellRendererParams={{ id: "abcdk-mini-chart" }}
            />
            {/* Create columns from state variable 'abcdkColumns' */}
            {abcdkColumns?.map((column) => (
              <AgGridColumn
                {...column}
                flex={1}
                key={column.colId}
                headerName={column.label ? column.label.substring(0, 3) : column.label}
                headerTooltip={column.label}
                columnGroupShow='open'
                type='numericColumn'
                chartDataType='series'
                valueGetter={(params) => {
                  return params.data.resultats?.abcdk?.find((apt) => {
                    return apt.label === column.label;
                  })?.score_percent;
                }}
                cellStyle={cellStyleABCDK}
              />
            ))}
          </AgGridColumn>
        )}

        <AgGridColumn>
          <AgGridColumn
            headerName='Commentaire'
            pinned='right'
            field='commentaire'
            width={300}
            editable={true}
            sortable={false}
            singleClickEdit={true}
            suppressKeyboardEvent={(params) => true}
            cellEditor='agLargeTextCellEditor'
            cellEditorParams={{ maxLength: 1000, rows: 20, cols: 120 }}
            onCellValueChanged={onCohorteCandidatCellValueChanged}
          />
        </AgGridColumn>
      </AgGridReact>
    </div>
  );
}
