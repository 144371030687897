import request from "../../utils/request";

export const CohortTransferService = (id) => {
  const transfer = (data) => {
    return request({
      url: `cohortes/${id}/cohortes-candidats/transfer`,
      data: data,
      method: "POST",
    });
  };

  return {
    transfer,
  };
};
