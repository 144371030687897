import React, { useEffect, useState } from 'react';
import useLocationSearch from '../../../utils/use-location-search';
import queryString from "query-string";
import { useLocation } from 'react-router-dom';


const CustomFiltersToolPanel =  (params) => {
  const location = useLocation();

  const [groupes, setGroupes] = useState([]);
  const [toBeHandled, setToBeHandled] = useState();
  const [candidat, setCandidat] = useState(queryString.parse(location?.search)["candidat"]);
  const [codePermanent, setCodePermanent] = useState(queryString.parse(location?.search)["code_permanent"]);
  const [groupe, setGroupe] = useState(queryString.parse(location?.search)["groupe"]);
  const [statut, setStatut] = useState(queryString.parse(location?.search)["statut"]);
  const { setValue: setLocationParamCandidat } = useLocationSearch("candidat");
  const { setValue: setLocationParamCodePermanent } = useLocationSearch("code_permanent");
  const { setValue: setLocationParamStatut } = useLocationSearch("statut");
  const { setValue: setLocationParamGroupe } = useLocationSearch("groupe");

  const filterBy = (colId, value)=>{
    let instance = params.api.getFilterInstance(colId);
    instance.setModel({ type: "contains", filter: value });
    instance.applyModel();
    params.api.onFilterChanged();
  }

  const filterByToBeHandled = (checked)=>{
    let instance = params.api.getFilterInstance("to_be_handled");
    if(checked === false){
      instance.setModel(null);
    }else {
      instance.setModel({ type: "equal", filter: "Oui" });
    }
    instance.applyModel();
    params.api.onFilterChanged();
  }

  useEffect(() => {
    const handleModelUpdated = () => {
      // Groups
      setGroupes(params.api.context.groupes);

      // ToBeHandled total
      let toBeHandled = 0;
      params.api.forEachNode((rowNode)=>{
        if(rowNode.data.to_be_handled === 1){
          toBeHandled += 1;
        }
        setToBeHandled(toBeHandled);
      });
    };

    params.api.addEventListener('modelUpdated', handleModelUpdated);
    return () => params.api.removeEventListener('modelUpdated', handleModelUpdated);
  }, [params.api, setCodePermanent]);


  return (
    <div style={{ position: 'absolute', top:'15px', left:'33px', right:'10px', bottom:'10px'}}>
      <p className="is-size-6 mb-2">Filtres</p>
      <div className="field">
      <label className="label is-small has-text-weight-normal">Nom</label>
      <div className="control is-expanded">
      <input type="text" className="input is-small" defaultValue={candidat} onChange={(e)=>{setCandidat(e.target.value); filterBy("nom_complet", e.target.value); setLocationParamCandidat(e.target.value); }} />
      </div>
      </div>
      <div className="field">
        <label className="label is-small has-text-weight-normal">Code permanent</label>
        <div className="control is-expanded">
        <input type="text" className="input is-small" defaultValue={codePermanent} onChange={(e)=>{setCodePermanent(e.target.value);filterBy("code_permanent", e.target.value); setLocationParamCodePermanent(e.target.value);}} />
        </div>
      </div>
      <div className="field">
        <label className="label is-small has-text-weight-normal">Statut</label>
        <div className="select is-small is-fullwidth">
          <select value={statut} onChange={(e)=>{setStatut(e.target.value); filterBy("statut", e.target.value); setLocationParamStatut(e.target.value);}}>
            <option value=""></option>
            <option value="Accepté">Accepté</option>
            <option value="Refusé">Refusé</option>
            <option value="Attente">Attente</option>
            <option value="À l'étude">À l'étude</option>
            <option value="Abandon">Abandon</option>
            <option value="Désisté">Désisté</option>
          </select>
        </div>
      </div>
      <div className="field">
        <label className="label is-small has-text-weight-normal">Groupe</label>
        <div className="select is-small is-fullwidth">
          <select value={String(groupe)} onChange={(e)=>{setGroupe(e.target.value); filterBy("groupe", e.target.value); setLocationParamGroupe(e.target.value)}}>
            <option value=""></option>
            {groupes?.map((item) => <option key={item.id} value={item.id}>{item.label}</option>   )}
          </select>
        </div>
      </div>
      <div className="field">
        <label className="label is-small has-text-weight-normal">À traiter <span className="has-text-grey" hidden={typeof toBeHandled === 'undefined'}>({toBeHandled})</span></label>
        <div className="control is-expanded">
          <label className="label is-small has-text-weight-normal"><input type="checkbox" className="checkbox" name="to_be_handled" value="Oui" onChange={(e)=>filterByToBeHandled(e.target.checked)} /></label>
        </div>
      </div>
    </div>
  );
};

export default CustomFiltersToolPanel;