import React from "react";

const QuickFilter = ({ gridRef }) => {
  const onCandidatChange = (e) => {
    gridRef.current.api.setQuickFilter(e.target.value);
  };

  return (
    <div id="quick-filter" className="field" style={{ width: 225 }}>
      <label className="label is-small has-text-weight-normal">Filtre</label>
      <div className="control is-small is-fullwidth">
        <input
          type="text"
          placeholder="Candidat, code permanent, etc."
          className="is-small input"
          onChange={onCandidatChange}
        />
      </div>
    </div>
  );
};

export default QuickFilter;
