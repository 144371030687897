import React, { useEffect, useState } from 'react';
import { useSwrCohortes, useSwrCohortesCandidats } from "../../utils/use-swr";
import CohortDetailsGrid from "./cohortDetailsGrid";
import { useParams } from "react-router-dom";
import Header from "./header";
import ButtonBar from "./buttonBar";
import { CohortesCandidatsService } from "../../services/api/commonServices";
import _ from "lodash";
import queryString from "query-string";
import { AdminServices } from "../../services/api/adminServices";
import PageLoader from "../../components/pageLoader/PageLoader";
import { CohortTransferService } from "../../services/api/cohortTranferService";

export default function CohortDetails(props) {
  const { location } = props;
  // ahhh clarity... the id here is cohortes_candidats.id, for the unfortunate soul that may have to transfer results from a demandeur to another one via api, in some future...
  const { id } = useParams();

  const { data, mutate, clearCache } = useSwrCohortes(null, id);
  const { data: candidatsCohortes, mutate: candidatsCohortesMutate, clearCache: candidatsCohortesClearCache } = useSwrCohortesCandidats(id, null);
  
  const [gridParams, setGridParams] = useState();
  const [groupes, setGroupes] = useState();

  const candidatFilterValue = queryString.parse(location?.search)["candidat"];
  const codePermanentFilterValue = queryString.parse(location?.search)["code_permanent"];
  const groupeFilterValue = queryString.parse(location?.search)["groupe"];
  const statutFilterValue = queryString.parse(location?.search)["statut"];

  const [selection, setSelection] = useState(() => []);
  const onSelectionChanged = (newValues) => setSelection(newValues);

  let chartRef;
  const [competencesColumns, setCompetencesColumns] = useState([]);
  const [aptitudesColumns, setAptitudesColumns] = useState([]);
  const [abcdkColumns, setABCDKColumns] = useState([]);

  const [compilingResults, setCompilingResults] = useState(false);
  const compileResults = () => {
    if (compilingResults) {
      return;
    }
    setCompilingResults(true);
    let payload;
    if(selection.length){
      payload = {candidats:  _.map(selection, "candidat_id").join()};
    }

    return AdminServices()
      .cohorteCompileResults(id, payload)
      .then(() => {
        setCompilingResults(false);
        candidatsCohortesMutate(); // Revalidate data
      });
  };

  useEffect(() => {
    return () => {
      clearCache();
      //gridParams?.api.refreshClientSideRowModel('aggregate');
    };
  }, [data, clearCache]);

  useEffect(() => {
    return () => {
      candidatsCohortesClearCache();
      //gridParams?.api.refreshClientSideRowModel('aggregate');
    };
  }, [candidatsCohortes, candidatsCohortesClearCache, gridParams]);

  useEffect(() => {
    setGroupes(data?.groupes);
    gridParams?.api.refreshClientSideRowModel('aggregate');
  }, [data, setGroupes, gridParams]);

  useEffect(() => {
    let uniqueCompetences = [],
      uniqueAptitudes = [],
      uniqueABCDK = [];
    let abcdkOrder = { Analytique: 10, Pragmatique: 20, "Émotivo-Relationnel": 30, "Visuo-Imaginatif": 40, Kinesthesique: 50 };
    candidatsCohortes?.forEach((item) => {
      item.resultats?.competences?.forEach((comp) => {
        if (!uniqueCompetences.find((uniqComp) => uniqComp.label === comp.label)) {
          uniqueCompetences.push({ colId: "comp_" + comp.label, label: comp.label, score_percent: comp.score_percent, interpretation_id: comp.interpretation_id });
        }
      });
      item.resultats?.aptitudes?.forEach((apt) => {
        if (!uniqueAptitudes.find((uniqApt) => uniqApt.label === apt.label)) {
          uniqueAptitudes.push({ colId: "apt_" + apt.label, label: apt.label, score_percent: apt.score_percent, interpretation_id: apt.interpretation_id });
        }
      });
      item.resultats?.abcdk?.forEach((abc) => {
        if (!uniqueABCDK.find((uniqAbc) => uniqAbc.label === abc.label)) {
          uniqueABCDK.push({ colId: "abc_" + abc.label, label: abc.label, score_percent: abc.score_percent, interpretation_id: abc.interpretation_id });
        }
      });
    });

    // Sort ABCDK with custom order
    uniqueABCDK.sort(function (o1, o2) {
      return abcdkOrder[o1.label] - abcdkOrder[o2.label];
    });

    setCompetencesColumns(uniqueCompetences);
    setAptitudesColumns(uniqueAptitudes);
    setABCDKColumns(uniqueABCDK);
    gridParams?.api.refreshClientSideRowModel('aggregate');
  }, [candidatsCohortes, gridParams]);

  ////////////////////////////////////////////////
  // update Cohortes-Candidats selection
  ////////////////////////////////////////////////

  const assignSelectionToGroupClicked = (groupeId) => {
    return CohortesCandidatsService(id)
      .bulkPatch("ids=" + _.map(selection, "id").join(), { groupe_id: groupeId })
      .then(() => {
        candidatsCohortesMutate(); // Revalidate data
      });
  };
  const assignSelectionToCohorteClicked = (cohorteId) => {
    gridParams.api.showLoadingOverlay();
    CohortTransferService(id)
      .transfer({ ids: _.map(selection, "id").join(), cohorte_id: cohorteId })
      .then(() => {
        gridParams.api.deselectAll();
        gridParams.api.hideOverlay();
        candidatsCohortesMutate(); // Revalidate data
      });
  };

  // Create chart via grid integrated charts (not standalone)
  const createChart = (params) => {
    // destroy existing chart
    if (chartRef) {
      chartRef.destroyChart();
    }

    // Title
    let title = data?.demandeur?.name + " / " + data?.formation?.label + " / " + data?.label;

    // Get subtitle from applied filters
    let subtitle = params.title + ": ";
    if (statutFilterValue !== undefined) {
      subtitle += "Statut: " + statutFilterValue + ", ";
    }
    if (groupeFilterValue !== undefined) {
      subtitle +=
        "Groupe: " +
        groupes?.find((grp) => {
          return grp.id === parseInt(groupeFilterValue);
        })?.label +
        ", ";
    }
    if (candidatFilterValue !== undefined) {
      subtitle += "Candidat: " + candidatFilterValue + ", ";
    }
    if (codePermanentFilterValue !== undefined) {
      subtitle += "Code permanent: " + codePermanentFilterValue + ", ";
    }
    // Remove last ", ";
    subtitle = subtitle.replace(/,\s*$/, "");
    // Show subtitle for empty filters
    if (subtitle === params.title + ": ") {
      subtitle += "Pour la cohorte entière";
    }

    const column = gridParams.columnApi.getColumn(params.columnId);
    if (column?.parent?.groupId) {
      // NOTE 1: columns used in the chart need to be visible (colapsed group will not work, will show "No data available to be charted")
      gridParams.columnApi.setColumnGroupOpened(column.parent.groupId, true);

      // Sort grid so the chart is in descending order
      gridParams.columnApi.applyColumnState({
        state: [
          {
            colId: params.columnId,
            sort: "desc",
          },
        ],
        defaultState: { sort: null },
      });

      // NOTE 2: columns used in the chart need to be declared in the grid with chartDataType property (ex: chartDataType="series" or chartDataType="category")
      var chartParams = {
        cellRange: {
          columns: [params.columnId, "nom_complet"],
        },
        chartType: "groupedBar",
        aggFunc: "sum",
        chartThemeOverrides: {
          common: {
            title: {
              enabled: true,
              text: title,
            },
            subtitle: {
              enabled: true,
              text: subtitle,
            },
            axes: {
              number: {
                tick: { count: 4 },
                min: 0,
                max: 100,
              },
            },
          },
        },
      };

      chartRef = gridParams.api.createRangeChart(chartParams);
    } else {
      // TODO: maybe show toast, but this should not happen
    }
  };

  return (
    <>
      <div id='resultats' className='pt-4 pl-2 pr-2'>
        {compilingResults && <PageLoader />}
        <Header data={data} />

        <ButtonBar
          data={data}
          gridParams={gridParams}
          selection={selection}
          onGroupesModifiedCb={() => {
            mutate();
            candidatsCohortesMutate();
          }}
          assignSelectionToGroupClicked={assignSelectionToGroupClicked}
          assignSelectionToCohorteClicked={assignSelectionToCohorteClicked}
          competencesColumns={competencesColumns}
          aptitudesColumns={aptitudesColumns}
          abcdkColumns={abcdkColumns}
          createChart={createChart}
          compileResultsClicked={compileResults}
          compilingResults={compilingResults}
          onCandidateAddedCB={candidatsCohortesMutate}
        />

        <CohortDetailsGrid
          data={candidatsCohortes}
          mutate={candidatsCohortesMutate}
          groupes={groupes}
          setGridParamsCb={setGridParams}
          statutFilterValue={statutFilterValue}
          groupeFilterValue={groupeFilterValue}
          onSelectionChangedCb={onSelectionChanged}
          competencesColumns={competencesColumns}
          aptitudesColumns={aptitudesColumns}
          abcdkColumns={abcdkColumns}
        />
      </div>
    </>
  );
}
