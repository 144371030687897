import React from "react";
import { useSwrFormations } from "../../utils/use-swr";
import GenericSelectFilter from "./GenericSelectFilter";
import useLocationSearch from "../../utils/use-location-search";

const FormationsFilter = ({width}) => {
  const { data, isError, isLoading } = useSwrFormations();
  const { value, setValue } = useLocationSearch('formations');

  return <GenericSelectFilter label='Formation' width={width} isLoading={isLoading} isError={isError} value={value} onChange={setValue} data={data} labelKey='label' />;
};

export default FormationsFilter;
