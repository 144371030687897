import React, { useEffect } from "react";
import _ from "lodash";
import Chart from "chart.js";

export default function MiniBarChartCellRenderer({ id, value }) {
  const ctx = React.createRef();
  useEffect(() => {
    let myBarChart;
    if (typeof myBarChart !== "undefined") myBarChart.destroy();
    const myChartRef = ctx.current.getContext("2d");
    const options = {
      type: "bar",
      responsive: true,
      maintainAspectRatio: false,
      data: {
        labels: _.map(value, "heading_fr"),
        datasets: [
          {
            barPercentage: 0.6,
            data: _.map(value, "score_percent"),
            backgroundColor: "rgba(132, 206, 230, 1)",
          },
        ],
      },
      options: {
        tooltips: {
          enabled: false,
        },
        animation: {
          duration: 0,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 2,
            bottom: 0,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: { display: false },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: { display: false, min: 0, max: 100, beginAtZero: true },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },
    };
    myBarChart = new Chart(myChartRef, options);
  }, [ctx, value]);
  return (
    <div
      className="mini-chart"
      style={{ position: "relative", width: "55px", height: "32px" }}
    >
      <canvas id={id} ref={ctx} width="55" height="32" />
    </div>
  );
}
