import React from "react";

const GenericSelectFilter = ({ label, width, isLoading, isError, labelKey, onChange, value, data }) => {
  return (
    <div className={`column is-${width ? width : 3}`}>
      <div className='field'>
        <label className='label is-small'>{label}</label>
        <div className='control is-expanded'>
          <div className={`select is-fullwidth ${isLoading ? "is-loading" : ""}`}>
            <select disabled={isLoading || isError} value={value} onChange={(e) => onChange?.(e.target.value)}>
              <option>{isError && "(Erreur)"}</option>
              {data &&
                data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item[labelKey]}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericSelectFilter;
