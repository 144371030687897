import React from "react";
import _ from "lodash";
import ChartContainer from "./ChartContainer";

export default function AptitudesChart({ title, data, width, height }) {
  const options = {
    type: "bar",
    data: {
      labels: _.map(data, "label"),
      datasets: [
        {
          data: _.map(data, "score_percent"),
          backgroundColor: "rgba(132, 206, 230, 1)",
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 10,
          top: 0,
          bottom: 0,
          right: 10,
        },
      },
      tooltips: {
        displayColors: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
              stepSize: 50,
              callback: function (value, index, values) {
                return index === 0 ? value + "%" : value;
              },
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              callback: function (value, index, values) {
                value = "" + value; // Make sure we deal with a string
                return value.substring(0, 3);
              },
            },
            gridLines: { display: false },
          },
        ],
      },
    },
  };
  return (
    <ChartContainer
      id="aptitudes-chart-canvas"
      title={title}
      data={data}
      options={options}
      width={width}
      height={height}
    />
  );
}
