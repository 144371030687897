import React from "react";
import {
  useSwrCandidates,
  useSwrCohortes,
  useSwrSeances,
} from "../../utils/use-swr";
import { useHistory, useParams } from "react-router-dom";
import GenericAGGrid from "../../components/grids/GenericAGGrid";
import { AgGridColumn } from "@ag-grid-community/react";

export default function CandidateDetails(props) {
  const { location } = props;
  const history = useHistory();
  const { id } = useParams();
  const { data, isError} = useSwrCandidates(location.search, id);
  const { data: cohortes, isError: isCohortesError } = useSwrCohortes(`?candidat=${id}`);
  const { data: seances, isError: isSeancesError } = useSwrSeances(`?candidat=${id}`);

  if (isError) return <p>Erreur: {isError.statusText}</p>;

  if (!data) return null;

  return (
    <div className='section'>
      <div id='resultats' className='container'>
        <div className='columns'>
          <div className='column is-1'>
            <button className='button is-outlined' onClick={history.goBack}>
              &lt;
            </button>
          </div>
          <div className='column'>
            <p className='title is-3'>
              {data.nom}, {data.prenom}
            </p>
          </div>
        </div>
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <p className='buttons'>
                <button className='button is-small is-outlined'>Assigner à une cohorte</button>
              </p>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              <button className='button is-small is-outlined'>Modifier la fiche</button>
            </div>
          </div>
        </div>
        <p className='title is-4'>Cohortes</p>
        <GenericAGGrid
          data={cohortes}
          error={isCohortesError}
          height={200}
          gridOptions={{ onRowClicked: (params) => history.push(`/cohortes/${params.data.id}`) }}
        >
          <AgGridColumn headerName='Établissement' field='demandeur' />
          <AgGridColumn headerName='Nom' field='nom' />
          <AgGridColumn headerName='Statut' valueGetter={(params) => params.data.candidats[0].statut} />
          <AgGridColumn headerName='Sélection' field='deadline' />
        </GenericAGGrid>

        <p className='title is-4'>Séances</p>
        <GenericAGGrid
          data={seances}
          error={isSeancesError}
          height={200}
          gridOptions={{onRowClicked: (params) => history.push(`/seances/${params.data.id}`)}}
        >
          <AgGridColumn headerName='Établissement' field='demandeur' />
          <AgGridColumn headerName='Nom' field='label' />
          <AgGridColumn headerName='Début' field='dt_debut' />
          <AgGridColumn headerName='Fin' field='dt_fin' />
        </GenericAGGrid>
      </div>
    </div>
  );
}
