import React from "react";

// Error boundaries currently have to be classes.
class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }
  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <h1>Oups...</h1>
          <p>Une erreur s'est produite.</p>
          <p>{error}</p>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
