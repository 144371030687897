import moment from "moment";

const ageValueGetter = (params) => {
    if (!params.data.date_naissance) {
      return null;
    }
    if (params.data.util_sess_dt_termine) {
      return moment(params.data.util_sess_dt_termine).diff(params.data.date_naissance, "years");
    }
    return moment(params.data.dt_ajout).diff(params.data.date_naissance, "years");
  };

export default ageValueGetter;