// import jwtDecode from "jwt-decode";
import request from "../../utils/request";

const login = (email, password) => {
  return request({
    url: `/login`,
    method: "POST",
    data: { username: email, password: password },
  });
};

const logout = () => {
  return request({
    url: `/logout`,
    method: "GET",
  });
};

const AuthService = {
  login,
  logout,
};

export default AuthService;
