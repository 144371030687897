import React, { useState } from "react";
import GroupEditModal from "./modals/GroupEditModal";
import GroupViewChart from "./charts/GroupViewChart";
import { useAuth } from "../../utils/use-auth";
import AddCandidateModal from "./modals/AddCandidateModal";

const ButtonBar = ({ 
    data
  , selection
  , onGroupesModifiedCb
  , gridParams
  , assignSelectionToGroupClicked
  , assignSelectionToCohorteClicked
  , competencesColumns
  , aptitudesColumns
  , abcdkColumns
  , createChart
  , compileResultsClicked
  , compiliingResults
  , onCandidateAddedCB }) => {
  let [isGroupEditModalVisible, setGroupEditModalVisibility] = useState(false);
  let [groupViewChartParams, setGroupViewChartParams] = useState({visible: false});
  let [addCandidateModalVisible, setAddCandidateModalVisible] = useState(false);

  const auth = useAuth();

  // ** Left here as a reference to use ag-grid charts as standalone (ag-charts).
  // const createChartStandalone = (params) => {  

  //   let chartData = [];
  //   candidatsCohortes.forEach((cc) => {            
  //     let candidateData = {nom_complet: cc.nom_complet, score_percent: undefined};
  //     cc.resultats?.[params.type]?.forEach((result) => {        
  //       if (result.interpretation_id === params.interpretation_id) {          
  //         candidateData.score_percent = result.score_percent;
  //       }  
  //     });                  
  //     chartData.push(candidateData);
  //   });

  //   // Sort results in descending order
  //   chartData.sort((a, b) => (a.score_percent > b.score_percent) ? -1 : 1);
    
  //   let chartParams = {
  //     data: chartData,
  //     visible: true,
  //     title: params.title,
  //     type: params.type
  //   }
  //   setGroupViewChartParams(chartParams);
  // }

  return (
    <div id='button-bar mb-2'>
      <div className='level'>
        <div className='level-left'>
          {/* <div className='level-item'>
            <div className='buttons'>
              <button className='button is-small is-outlined' onClick={() => gridParams.api.deselectAll() } disabled={!selection?.length}>
                Désélectionner tout
              </button>
            </div>
          </div> */}
          <div className='level-item'>
            <div className='select is-small is-outlined'>
              <select
                defaultValue={""}
                onChange={(e) => {
                  assignSelectionToGroupClicked(e.target.value);
                  // Reset to default value (without triggering a change event)
                  setTimeout(() => (e.target.selectedIndex = 0), 500);
                }}
                disabled={!selection?.length || !data?.groupes?.length}>
                <option value='' disabled>
                  Assigner un groupe à la sélection
                </option>
                <option value=''>(Aucun)</option>
                {data?.groupes?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='level-item'>
            <div className='select is-small is-outlined'>
              <select
                defaultValue={""}
                onChange={(e) => {
                  assignSelectionToCohorteClicked(e.target.value);
                  // Reset to default value (without triggering a change event)
                  setTimeout(() => (e.target.selectedIndex = 0), 500);
                }}
                disabled={!selection?.length || !data?.transfer_to_cohortes?.length}>
                <option value='' disabled>
                  Transférer la sélection
                </option>
                {data?.transfer_to_cohortes?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='level-right'>
        { auth.user?.type_utilisateur === 9 &&
        <div className='level-item'>
          <button className='button is-small is-outlined' onClick={() => setAddCandidateModalVisible(true)}>Ajouter un candidat</button>
        </div>
        }
        { auth.user?.type_utilisateur >= 4 &&
          <div className='level-item'>
            <div className='select is-small'>
              <select 
                onChange={(e) => {           
                  // This is the only method I found that works to access data-attributes with react. FT:
                  var dataset = e.target.options[e.target.selectedIndex].dataset;

                  // Create chart using integrated charts (through grid):
                  createChart({columnId: e.target.value, title: dataset.label});
                  // Create chart using AgCharts (standalone library):
                  //createChartStandalone({columnId: e.target.value, title: dataset.label, type: dataset.type, interpretation_id: parseInt(dataset.interpretation_id)});
                  
                  // Reset to default value (without triggering a change event)
                  setTimeout(() => (e.target.selectedIndex = 0), 500);
                }}                
              >
              <option value="">Graphique vue d'ensemble</option>
              <optgroup label="Compétences"> 
                {competencesColumns?.map((item) => {  
                  return <option key={item.colId} data-label={item.label} data-type='competences' data-interpretation_id={item.interpretation_id} value={item.colId}>{item.label}</option>
                })}
              </optgroup>
              <optgroup label="Aptitudes">
                {aptitudesColumns?.map((item) => {  
                  return <option key={item.colId} data-label={item.label} data-type='aptitudes' data-interpretation_id={item.interpretation_id} value={item.colId}>{item.label}</option>
                })}
              </optgroup>
              <optgroup label="ABCDK">
                {abcdkColumns?.map((item) => {  
                  return <option key={item.colId} data-label={item.label} data-type='abcdk' data-interpretation_id={item.interpretation_id} value={item.colId}>{item.label}</option>
                })}
              </optgroup>
              </select>              
            </div>
          </div>
          }
          { auth.user?.type_utilisateur >= 5 &&
          <div className='level-item'>
              <button className='button is-small is-outlined' onClick={() => compileResultsClicked()} disabled={!selection?.length || compiliingResults}>
                Compiler les résultats
              </button>
          </div>
          }
          <div className='level-item'>
            <div className='buttons'>
              <button className='button is-small is-outlined' onClick={() => setGroupEditModalVisibility((isGroupEditModalVisible = !isGroupEditModalVisible))}>
                Modifier les groupes
              </button>
            </div>
          </div>
          <div className='level-item'>
            <div className='buttons'>
              <button
                className='button is-small is-outlined'
                onClick={() => gridParams.api.exportDataAsExcel()}>
                Télécharger .xls
              </button>
            </div>
          </div>
        </div>
      </div>

      <GroupEditModal
        cohorteId={data?.id}
        visible={isGroupEditModalVisible}
        onGroupesModifiedCb={onGroupesModifiedCb}
        groupes={data?.groupes}
        close={() => setGroupEditModalVisibility(false)}
      />

      <GroupViewChart
        params={groupViewChartParams}
        close={() => setGroupViewChartParams({...groupViewChartParams, visible: false})}
      />

      <AddCandidateModal
        cohorteId={data?.id}
        visible={addCandidateModalVisible}
        close={()=>setAddCandidateModalVisible(false)}
        onCandidateAddedCB={onCandidateAddedCB}
      />
    </div>
  );
};

export default ButtonBar;
