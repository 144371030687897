const baseColors = {
  red: "rgb(192, 0, 0)",
  yellow: "rgb(255, 192, 0)",
  green: "rgb(112, 173, 71)",
};

export const aptitudesColorRulesByInterpretationId = {
  112: {
    [baseColors.red]: { min: 0, max: 29 },
    [baseColors.yellow]: { min: 30, max: 49 },
    [baseColors.green]: { min: 80, max: 100 },
  },
  113: {
    [baseColors.red]: { min: 0, max: 29 },
    [baseColors.yellow]: { min: 30, max: 49 },
    [baseColors.green]: { min: 80, max: 100 },
  },
  114: {
    [baseColors.red]: { min: 0, max: 25 },
    [baseColors.yellow]: { min: 26, max: 45 },
    [baseColors.green]: { min: 80, max: 100 },
  },
  115: {
    [baseColors.red]: { min: 0, max: 29 },
    [baseColors.yellow]: { min: 30, max: 49 },
    [baseColors.green]: { min: 80, max: 100 },
  },
  116: {
    [baseColors.red]: { min: 0, max: 49 },
    [baseColors.yellow]: { min: 50, max: 69 },
    [baseColors.green]: { min: 90, max: 100 },
  },
  105: {
    [baseColors.red]: { min: 0, max: 40 },
    [baseColors.yellow]: { min: 41, max: 50 },
    [baseColors.green]: { min: 70, max: 100 },
  },
  106: {
    [baseColors.red]: { min: 0, max: 30 },
    [baseColors.yellow]: { min: 31, max: 40 },
    [baseColors.green]: { min: 70, max: 100 },
  },
  108: {
    [baseColors.red]: { min: 0, max: 30 },
    [baseColors.yellow]: { min: 31, max: 40 },
    [baseColors.green]: { min: 70, max: 100 },
  },
  110: {
    [baseColors.red]: { min: 0, max: 30 },
    [baseColors.yellow]: { min: 31, max: 40 },
    [baseColors.green]: { min: 70, max: 100 },
  },
  111: {
    [baseColors.red]: { min: 0, max: 25 },
    [baseColors.yellow]: { min: 26, max: 39 },
    [baseColors.green]: { min: 70, max: 100 },
  },
};
