const statusBar = {
  statusPanels: [
    {
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "right",
    },
    {
      statusPanel: "agSelectedRowCountComponent",
      align: "left",
    },
  ],
};

export default statusBar;