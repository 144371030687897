import React, { useEffect, useState } from 'react';
import { CandidatesService, CohortesCandidatsService } from "../../../services/api/commonServices";

const AddCandidateModal = ({ cohorteId, visible, close, onCandidateAddedCB }) => {
  const [codePerm, setCodePerm] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [langue, setLangue] = useState("fr");
  const [candidatId, setCandidatId] = useState();

  const [candidateFound, setCandidateFound] = useState();
  const [codePermValid, setCodePermValid] = useState();
  const [formValid, setFormValid] = useState();

  useEffect(() => {
    setCodePermValid(/^[a-zA-Z]{4}[0-9]{8}$/.test(codePerm));
  }, [codePerm, setCodePermValid]);

  useEffect(() => {
    if (!visible) return;
    setCodePerm("");
    setNom("");
    setPrenom("");
    setLangue("");
    setCandidatId();
    setCandidateFound();
  }, [visible]);

  useEffect(() => {
    let valid = true;
    if (!nom || !prenom || !langue) {
      valid = false;
    } else if (/^[a-zA-Z]{4}[0-9]{8}$/.test(codePerm) === false) {
      valid = false;
    }

    setFormValid(valid);
  }, [codePerm, nom, prenom, langue, setFormValid]);

  const search = (e) => {
    e.preventDefault();
    setCandidateFound();
    CandidatesService()
      .get(`?code_permanent=${codePerm}`)
      .then(
        (resp) => {
          setCandidateFound(resp.items?.length > 0);
          setCandidatId(resp.items[0]?.id ? resp.items[0].id : null);
          setNom(resp.items[0]?.nom ? resp.items[0].nom : "");
          setPrenom(resp.items[0]?.prenom ? resp.items[0].prenom : "");
          setLangue(resp.items[0]?.langue ? resp.items[0].langue : "fr");
        },
        (failResp) => {
          alert("Erreur");
        },
      );
  };

  const save = (e) => {
    e.preventDefault();
    CohortesCandidatsService(cohorteId)
      .post({ cohorte_id: cohorteId, candidat_id: candidatId, code_permanent: codePerm, nom: nom, prenom: prenom, langue: langue })
      .then(
        (resp) => {
          if (onCandidateAddedCB) {
            onCandidateAddedCB();
          }
          close();
        },
        (error) => {
          alert(error.data?.errors["already_exists"] ? error.data.errors["already_exists"] : "Erreur");
        },
      );
  };

  const reset = () => {
    setCandidateFound();
    setCandidatId();
    setNom("");
    setPrenom("");
    setLangue("");
  };

  return (
    <div id='add-candidate-modal' className={`modal ${visible ? "is-active" : ""}`}>
      <div
        className='modal-background'
        onClick={() => {
          close();
        }}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Ajouter un candidat</p>
          <button
            className='delete'
            aria-label='close'
            onClick={() => {
              close();
            }}></button>
        </header>
        <section className='modal-card-body'>
          <form id='add-candidate-form' autoComplete='off'>
            <fieldset className='mb-4'>
              <label className='label'>Code permanent</label>
              <div className='field has-addons'>
                <div className='control'>
                  <input
                    type='text'
                    name='code_permanent'
                    id='code_permanent'
                    placeholder='AAAA12345678'
                    className='input'
                    value={codePerm}
                    onChange={(event) => {
                      reset();
                      setCodePerm(event.target.value);
                    }}
                    maxLength='12'
                  />
                  <p className={`help has-text-white ${typeof candidateFound === "undefined" ? "" : "is-hidden"}`}>-</p>
                  <p className={`help has-text-grey-light ${typeof candidateFound === "undefined" ? "is-hidden" : ""}`}>
                    {candidateFound ? "Candidat trouvé" : "Nouveau candidat"}
                  </p>
                </div>
                <div className='control'>
                  <button className='button is-link' onClick={(e) => search(e)} disabled={!codePermValid}>
                    Rechercher
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset className='mb-4'>
              <div className='field'>
                <label className='label'>Nom</label>
                <div className='control'>
                  <input
                    type='text'
                    name='nom'
                    id='nom'
                    placeholder='Nom'
                    className='input'
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    disabled={candidateFound !== false}
                  />
                </div>
              </div>
              <div className='field'>
                <label className='label'>Prénom</label>
                <div className='control'></div>
                <input
                  type='text'
                  name='prenom'
                  id='prenom'
                  placeholder='Prénom'
                  className='input'
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  disabled={candidateFound !== false}
                />
              </div>
              <div className='field'>
                <label className='label'>Langue</label>
                <div className='select'>
                  <select id='langue' value={langue} disabled={candidateFound !== false} onChange={(e) => setLangue(e.target.value)}>
                    <option value='fr'>Fr</option>
                    <option value='en'>En</option>
                  </select>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <footer className='modal-card-foot'>
          <div className='field is-grouped'>
            <button className='button is-primary' onClick={(e) => save(e)} disabled={!formValid}>
              Ajouter
            </button>
            <button
              className='button is-ghost'
              onClick={() => {
                close();
              }}>
              Fermer
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AddCandidateModal;