const scorePercentValueGetter = (params, column) => {
  let score_percent = params.data.resultats?.competences?.find((comp) => {
    return comp.label === column.label;
  })?.score_percent;
  return score_percent === null && params.data.util_sess_dt_termine !== null
    ? 0
    : score_percent;
};

export default scorePercentValueGetter;
