import React from "react";
import EtablissementsFilter from "../../components/filters/EtablissementsFilter";
import FormationsFilter from "../../components/filters/FormationsFilter";
import ListFilters from "../../components/ListFilters";
import { useSwrCohortes, useSwrSeances } from "../../utils/use-swr";
import CohortsCards from "./cohortsCards";
import SeancesCards from "../seances/seancesCards";
import { useHistory } from "react-router-dom";
import CandidatsFilter from "../../components/filters/CandidatsFilter";
import CodePermanentFilter from "../../components/filters/CodePermanentFilter";

export default function Home(props) {
  const { location } = props;
  const history = useHistory();
  const { data: cohortes, isError: isCohortesError } = useSwrCohortes(location.search ? location.search + '&limit=12' : '?limit=12', null);
  const { data: seances, isError: isSeancesError } = useSwrSeances(location.search ? location.search + '&limit=12' : '?limit=12', null);

  return (
    <div id='Seances' className='section'>
      <div className='container'>
        <Filters />
        <h1 className='title'>Cohortes</h1>
        {isCohortesError && <p>Erreur</p>}
        <CohortsCards data={cohortes} />
        <div className='buttons is-centered'>
          <button className='button is-primary' onClick={() => history.push("/cohortes" + location.search)}>
            Voir plus
          </button>
        </div>
        <hr />
        <h1 className='title'>Séances de tests</h1>
        {isSeancesError && <p>Erreur</p>}
        <SeancesCards data={seances} />
        <div className='buttons is-centered'>
          <button className='button is-primary' onClick={() => history.push("/seances" + location.search)}>
            Voir plus
          </button>
        </div>
      </div>
    </div>
  );
}

const Filters = () => (
  <ListFilters>
    <CandidatsFilter width='3' />
    <CodePermanentFilter width='3' />
    <EtablissementsFilter width='3' />
    <FormationsFilter width='3' />
  </ListFilters>
);
