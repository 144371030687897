const BarChartPlugins = {
  dottedLines: {
    afterDatasetsDraw: function (chartInstance, easing) {
      var avgMin = chartInstance.config.avgMin;
      var avgMax = chartInstance.config.avgMax;

      if (typeof avgMin === "undefined") return;
      if (typeof avgMax === "undefined") return;

      var ctx = chartInstance.chart.ctx,
        xaxis = chartInstance.scales["x-axis-0"],
        yaxis = chartInstance.scales["y-axis-0"];

      ctx.save();

      ctx.beginPath();
      ctx.moveTo(xaxis.left, yaxis.getPixelForValue(avgMin));
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#194b63";
      ctx.setLineDash([5, 3]);
      ctx.lineTo(xaxis.right, yaxis.getPixelForValue(avgMin));
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(xaxis.left, yaxis.getPixelForValue(avgMax));
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#194b63";
      ctx.setLineDash([5, 3]);
      ctx.lineTo(xaxis.right, yaxis.getPixelForValue(avgMax));
      ctx.stroke();

      ctx.restore();
    },
  },
};

export default BarChartPlugins;
