import React from "react";

const MainWrapper = ({ children }) => {
  return (
    <div id='main' className='has-background-light'>
      {children}
    </div>
  );
};

export default MainWrapper;
