import React from "react";

const EditModal = ({ visible, close }) => {
  return (
    <div className={`modal ${visible ? "is-active" : ""}`}>
      <div className="modal-background" onClick={() => close()}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Cohorte</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => close()}
          ></button>
        </header>
        <section className="modal-card-body">
          <ul>
            <li>Titre</li>
            <li>Établissement</li>
            <li>Formation</li>
            <li>Date butoir de sélection</li>
          </ul>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={() => close()}>
            Enregistrer
          </button>
          <button className="button" onClick={() => close()}>
            Annuler
          </button>
        </footer>
      </div>
    </div>
  );
};

export default EditModal;
