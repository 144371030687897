import React, { useRef, useState, useMemo, useEffect } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { useSwrCohortes } from "../../utils/use-swr";
import { useSwrCohortesCandidats } from "../../utils/use-swr";
import { useParams } from "react-router-dom";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import { GridChartsModule } from "@ag-grid-enterprise/charts";

import Header from "./header";
import columnDefs from "./columns/columnDefs";
import DetailCellRenderer from "./modules/detail/detailCellRenderer";
import { addCompetencesColumns } from "./columns/columnDefs/competences";
import { addAptitudesColumns } from "./columns/columnDefs/aptitudes";
import { addABCDKColumns } from "./columns/columnDefs/abcdk";
import statusBar from "./modules/statusBar/statusBar";
import sideBar from "./modules/sideBar/sideBarDef";
import ButtonBar from "./buttonBar";
import AddCandidateButton from "./buttons/AddCandidateButton";
import { useAuth } from "../../utils/use-auth";
import CompileResultsButton from "./buttons/CompileResultsButton";
import DownloadXLSButton from "./buttons/DownloadXLSButton";
import ShowchartsButton from "./buttons/ShowChartsButton";
import DefaultColDef from "./columns/columnDefs/defaultColDef";
import { StatusBarModule } from "@ag-grid-enterprise/all-modules";
import TransferCandidateButton from "./buttons/TransferCandidateButton";
import AssignToGroupButton from "./buttons/AssignToGroupButton";
import AssignToStatusButton from "./buttons/AssignToStatusButton";
import ModifyGroups from "./buttons/ModifyGroups";
import QuickFilter from "./filters/QuickFilter";
import ToBeHandledFilter from "./filters/ToBeHandleFilter";
import StatusFilter from "./filters/StatusFilter";
import GroupeFilter from "./filters/GroupeFilter";

//////////////////////////////////////////
// Helpers
//////////////////////////////////////////
export const isRowEnabled = (params) =>
  params.data?.transferred_at ? false : true;
export const isCellEditable = (params) => isRowEnabled(params);

//////////////////////////////////////////
// Main
//////////////////////////////////////////
export default function Cohort(props) {
  // Here "id" refers to cohortes.id (from location url params)
  // Referenced in this file as cohort_id
  const { id: cohort_id } = useParams();

  // Cohorte data for header (i.e. title, formation) and cohort groups
  // Referenced in this file as cohortData
  const { data: cohortData, mutate: cohortMutate } = useSwrCohortes(
    null,
    cohort_id
  );

  // Array of candidates that belongs to the current cohorte
  // Referenced in this file as cohortCandidatesData
  // TODO: Make API return cohort info (to avoid 2 calls, one for cohort and another for cohortCandidates)
  const {
    data: cohortCandidatesData,
    mutate: cohortCandidatesMutate,
    clearCache,
  } = useSwrCohortesCandidats(cohort_id, null);

  const gridRef = useRef();
  const auth = useAuth();

  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "calc(100% - 56px)", // 56px = header height
      display: "flex",
      position: "fixed",
      flexDirection: "column",
      zIndex: "999",
    }),
    []
  );

  const gridStyle = useMemo(
    () => ({
      height: "100%",
      width: "100%",
    }),
    []
  );

  const getRowId = useMemo(() => {
    return (params) => params.candidat_id;
  }, []);

  // Clear previous data as soon as the view loads
  useEffect(() => {
    clearCache();
  }, [clearCache]);

  const [authRef, setAuthRef] = useState(auth);
  useEffect(() => {
    setAuthRef(auth);
  }, [auth]);

  const defaultColDef = useMemo(() => {
    return DefaultColDef;
  }, []);

  const [selectedRows, setSelectedRows] = useState(
    gridRef.current?.api?.getSelectedRows()
  );

  const onColumnGroupOpened = (params) => {
    const colGroupsCloseOthers = ["competences", "aptitudes", "abcdk"];
    // if more than one group is opened, do nothing, it would be counter-productive
    let otherOpenedGroups = false;
    colGroupsCloseOthers.forEach((colGroup) => {
      if (
        colGroup !== params.columnGroup.groupId &&
        params.columnApi.getColumnGroup(colGroup)?.originalColumnGroup.expanded
      ) {
        otherOpenedGroups = true;
      }
    });
    if (otherOpenedGroups) {
      return;
    }
    if (colGroupsCloseOthers.indexOf(params.columnGroup.groupId) > -1) {
      params.columnApi.setColumnGroupOpened(
        "infoCandidats",
        !params.columnGroup.expanded
      );
    }
  };

  return (
    <div id="cohort" style={containerStyle}>
      <Header
        data={cohortData}
        right={[
          <>
            {auth.user?.type_utilisateur === 9 && (
              <AddCandidateButton
                cohortId={cohort_id}
                addedCB={cohortCandidatesMutate}
              />
            )}
          </>,
          <ModifyGroups
            cohortGroups={cohortData?.groupes || []}
            cohortId={cohort_id}
            modifiedCB={() => {
              cohortMutate();
              cohortCandidatesMutate();
            }}
          />,
          <DownloadXLSButton gridRef={gridRef} />,
        ]}
      />

      <ButtonBar
        left={[
          <QuickFilter gridRef={gridRef} />,
          <StatusFilter gridRef={gridRef} />,
          <GroupeFilter gridRef={gridRef} groups={cohortData?.groupes || []} />,
          <ToBeHandledFilter
            gridRef={gridRef}
            cohortCandidatesData={cohortCandidatesData}
          />,
        ]}
        right={[
          <>
            {auth.user?.type_utilisateur >= 4 && (
              <ShowchartsButton gridRef={gridRef} cohortData={cohortData} />
            )}
          </>,
        ]}
      />

      {selectedRows?.length > 0 && (
        <ButtonBar
          left={[
            <AssignToGroupButton
              gridRef={gridRef}
              groups={cohortData?.groupes || []}
              selectedRows={selectedRows}
              cohortId={cohort_id}
              assignedCB={cohortCandidatesMutate}
            />,
            <AssignToStatusButton
              gridRef={gridRef}
              selectedRows={selectedRows}
              cohortId={cohort_id}
              assignedCB={cohortCandidatesMutate}
            />,
            <TransferCandidateButton
              gridRef={gridRef}
              cohortAvailForTransfer={cohortData?.transfer_to_cohortes}
              selectedRows={selectedRows}
              cohortId={cohort_id}
              transferedCB={cohortCandidatesMutate}
            />,
            <>
              {auth.user?.type_utilisateur >= 5 && (
                <CompileResultsButton
                  selectedRows={selectedRows}
                  cohortId={cohort_id}
                  compiledCB={cohortCandidatesMutate}
                />
              )}
            </>,
          ]}
          right={[]}
        />
      )}

      <div style={gridStyle} className="ag-theme-balham">
        <AgGridReact
          ref={gridRef}
          rowData={cohortCandidatesData}
          context={{ groupes: cohortData?.groupes, authRef: authRef }}
          columnDefs={columnDefs}
          onColumnGroupOpened={onColumnGroupOpened}
          onGridReady={(params) => params.api.sizeColumnsToFit()}
          onGridColumnsChanged={(params) => params.api.sizeColumnsToFit()}
          onToolPanelVisibleChanged={(params) => params.api.sizeColumnsToFit()}
          rowSelection="multiple"
          // ImmutableData
          // From the doc: If you are going to be updating Row Data then it is strongly recommended to provide the getRowId callback
          // For example, if Rows are selected, and new Row Data is provided such that some Rows are removed, ***the grid is able to
          // maintain the selection*** across rows that exist in both the old and new lists of Row Data.
          // https://www.ag-grid.com/react-data-grid/react-hooks/#immutable-data
          getRowNodeId={getRowId}
          immutableData={true}
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////
          onSelectionChanged={(params) =>
            setSelectedRows(params.api.getSelectedRows())
          }
          // Call refreshHeader() to display selected over total in checkBox header col
          onRowSelected={(params) => params.api.refreshHeader()}
          onFilterChanged={(params) => params.api.refreshHeader()}
          /////////////////////////////////////////////////////////////////////////////
          sideBar={sideBar}
          suppressRowClickSelection={true}
          defaultColDef={defaultColDef}
          accentedSort={true}
          enableCellTextSelection={true}
          modules={[
            ClientSideRowModelModule,
            MasterDetailModule,
            MenuModule,
            ExcelExportModule,
            ColumnsToolPanelModule,
            FiltersToolPanelModule,
            GridChartsModule,
            StatusBarModule,
          ]}
          masterDetail={true}
          detailCellRendererFramework={DetailCellRenderer}
          detailRowAutoHeight={true}
          statusBar={statusBar}
          getRowClass={(params) =>
            isRowEnabled(params) ? null : "has-text-grey-light"
          }
          onCellEditingStarted={(params) =>
            isCellEditable(params) ? null : params.api.stopEditing()
          }
          onFirstDataRendered={(params) => {
            if (auth.user?.type_utilisateur >= 4) {
              addCompetencesColumns(gridRef);
              addAptitudesColumns(gridRef);
              addABCDKColumns(gridRef);
            }
            // Call sizeColumnsToFit for "flex: 1" on Commentaire to work
            params.api.sizeColumnsToFit();
            // Call refreshHeader for row count to work in the checkbox column to work
            params.api.refreshHeader();
          }}
        />
      </div>
    </div>
  );
}
