import React, { useState } from "react";
import GroupEditModal from "../modals/GroupEditModal";

const ModifyGroups = (props) => {
  const { cohortGroups, cohortId, modifiedCB } = props;
  const [isModalVisible, setModalVisibility] = useState(false);

  const onClick = () => {
    setModalVisibility(!isModalVisible);
  };
  return (
    <div>
      <button className="button is-small is-outlined" onClick={onClick}>
        Modifier les groupes
      </button>
      <GroupEditModal
        cohorteId={cohortId}
        visible={isModalVisible}
        onGroupesModifiedCb={modifiedCB}
        groupes={cohortGroups}
        close={() => setModalVisibility(false)}
      />
    </div>
  );
};

export default ModifyGroups;
