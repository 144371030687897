import React from "react";
import useLocationSearch from "../../utils/use-location-search";
import GenericTextFilter from "./GenericTextFilter";

const CodePermanentFilter = ({ width }) => {
  const { value, setValue } = useLocationSearch("code_permanent");

  return <GenericTextFilter label="Code Permanent" placeholder="AAAA12345678" width={width} value={value} onChange={setValue} />;
};

export default CodePermanentFilter;
