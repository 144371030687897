import { useEffect } from "react";
import useSWR, { cache } from "swr";
import { useAPIError } from "./use-api-error";
import {
  CandidatesService,
  CohortesCandidatsService,
  CohortesService,
  EtablissementsService,
  FormationsService,
  GroupesService,
  ResultatsService,
  SeancesService,
} from "../services/api/commonServices";


const swrConfig = {
  shouldRetryOnError: false,
  onError: () => cache.clear(),
  revalidateOnFocus: false, // Stop revalidate on focus
  refreshInterval: 0,
};

const useSwrGeneric = (service, swrId, filter, id) => {
  const fetcher = () =>
    service.get(filter, id).then((res) => {
      return res;
    });
  const { data, error, mutate } = useSWR(
    [swrId, id, filter],
    fetcher,
    swrConfig
  );
  const { addApiError } = useAPIError();

  const clearCache = ()=>{cache.clear()}

  useEffect(() => {
    if (error) {
      addApiError("Erreur", error.status);
    }
  }, [error, addApiError]);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
    clearCache: clearCache
  };
};

export const useSwrCohortes = (filter, id) =>
  useSwrGeneric(CohortesService(), "cohortes", filter, id);

export const useSwrCohortesCandidats = (cohorteId, filter, id) => useSwrGeneric(CohortesCandidatsService(cohorteId), "cohortes", filter, id);

export const useSwrSeances = (filter, id) =>
  useSwrGeneric(SeancesService(),"seances", filter, id);

export const useSwrResultats = (filter, id) =>
  useSwrGeneric(ResultatsService(),"resultats", filter, id);

export const useSwrCandidates = (filter, id) =>
  useSwrGeneric(CandidatesService(), "candidates", filter, id);

export const useSwrEtablissements = (filter, id) =>
  useSwrGeneric(EtablissementsService(), "etablissements", filter, id);

export const useSwrFormations = (filter, id) =>
  useSwrGeneric(FormationsService(), "formations", filter, id);

export const useSwrGroupes = (cohorteId, filter, id) =>
  useSwrGeneric(GroupesService(cohorteId), "groupes", filter, id);


