import { AgGridColumn } from "@ag-grid-community/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EtablissementsFilter from "../../components/filters/EtablissementsFilter";
import FormationsFilter from "../../components/filters/FormationsFilter";
import GenericTextFilter from "../../components/filters/GenericTextFilter";
import GenericAGGrid from "../../components/grids/GenericAGGrid";
import ListFilters from "../../components/ListFilters";
import { useSwrCohortes } from "../../utils/use-swr";

export default function Cohorts(props) {
  const history = useHistory();
  const { location } = props;
  const [gridParams, setGridParams] = useState();
  const [labelFilterValue, setLabelFilterValue] = useState();
  
  useEffect(() => {
    if  (!gridParams) return;
    gridParams.api.getFilterInstance("label").setModel({
      filterType: "string",
      type: "contains",
      filter: labelFilterValue,
    });
    gridParams.api.getFilterInstance("label").applyModel();
    gridParams.api.onFilterChanged();
  }, [labelFilterValue, gridParams]);

  const percent = (num, over)=>{
    if(!num) return null;
    if(!over) return null;

    return Math.round(num/over*100);
  }
  
  const gridOptions = {
    onRowClicked: (params) => {
      history.push(`${history.location.pathname}/${params.data.id}`);
    },
  };
  
  const { data, isError } = useSwrCohortes(location.search);
  
  return (
    <div className='container'>
      <div id='cohortes' className='section'>
        <h1 className='title'>Cohortes</h1>

        <ListFilters>
          <GenericTextFilter label='Cohorte' placeholder='Nom de la cohorte' onChange={setLabelFilterValue} />
          <EtablissementsFilter width='3' />
          <FormationsFilter width='3' />
        </ListFilters>

        <GenericAGGrid data={data} error={isError} gridOptions={gridOptions} setGridParamsCb={setGridParams}>
          <AgGridColumn headerName='Nom' field='label' sortable={true} sort='asc' />
          <AgGridColumn headerName='Formation' field='formation' valueGetter={(params) => params.data.formation?.label} />
          <AgGridColumn headerName='Établissement' field="etablissement" valueGetter={(params) => params.data.demandeur?.name} />
          <AgGridColumn headerName='Candidats' field='candidates_count' type='numericColumn' />
          <AgGridColumn headerName='À traiter' field='candidates_to_be_handled' type='numericColumn' />
          <AgGridColumn headerName='Absents' headerTooltip="Sessions de tests non-terminées" field="computed_sessions_not_completed_percent" valueGetter={(params)=>percent(params.data.sessions_not_completed, params.data.candidates_count)} valueFormatter={(params)=>percent(params.data.sessions_not_completed, params.data.candidates_count) + '%'} cellRenderer={(params)=>params.value+'% <span class="is-size-7 has-text-grey-light">('+params.data.sessions_not_completed+')</span>'}/>
          <AgGridColumn headerName='Date butoir' field='deadline' />
          <AgGridColumn headerName='Créée' field='created_at' />
        </GenericAGGrid>
      </div>
    </div>
  );
}
