import React, { useState } from "react";
import _ from "lodash";
import { AdminServices } from "../../../services/api/adminServices";

const CompileResultsButton = ({ selectedRows, cohortId, compiledCB }) => {
  const [isCompiling, setIsCompiling] = useState(false);

  const onClick = () => {
    setIsCompiling(true);
    let payload = { candidats: _.map(selectedRows, "candidat_id").join() };

    return AdminServices()
      .cohorteCompileResults(cohortId, payload)
      .then(() => {
        setIsCompiling(false);
        if (compiledCB) {
          compiledCB();
        }
      });
  };
  return (
    <button
      className="button is-small is-outlined"
      onClick={() => onClick()}
      disabled={!selectedRows?.length || isCompiling}
    >
      Compiler les résultats
    </button>
  );
};

export default CompileResultsButton;
