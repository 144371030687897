import React, { Children, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EditModal from "./modals/EditModal";

export default function Header({ data, right }) {
  const history = useHistory();
  const arrayRightChildren = Children.toArray(right);
  const [title, setTitle] = useState("--");
  const [demandeur, setDemandeur] = useState("--");
  const [formation, setFormation] = useState("--");

  let [isEditModalVisible, setEditModalVisibility] = useState(false);

  useEffect(() => {
    if (!data) return;
    setTitle(data.label);
    setDemandeur(data.demandeur ? data.demandeur.name : "--");
    setFormation(data.formation ? data.formation.label : "--");
  }, [data]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: 60,
        zIndex: 1000,
        backgroundColor: "slateblue",
      }}
    >
      <div className="level mt-1 mb-2 pr-5">
        <div className="level-left">
          <div className="level-item">
            <button
              className="button is-outlined is-small"
              onClick={history.goBack}
            >
              &lt;
            </button>
          </div>
          <div className="level-item ml-4">
            <div>
              <p className="title is-4 has-text-white">{title}</p>
              <p className="has-text-grey-light">
                {demandeur} / {formation}
              </p>
            </div>
          </div>
        </div>
        <div className="level-right">
          {Children.map(arrayRightChildren, (child, index) => {
            return (
              <div className="level-item" key={index}>
                {child}
              </div>
            );
          })}
        </div>
      </div>

      <EditModal
        visible={isEditModalVisible}
        data={data}
        close={() => setEditModalVisibility(false)}
      />
    </div>
  );
}
