import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function CohortsCards(props) {
  const { data } = props;

  if (!data || !data.map) {
    return null;
  }

  return (
    <div className='columns is-multiline'>
      {data.map((cohorte) => {
        return <Card key={cohorte.id} data={cohorte} />;
      })}
    </div>
  );
}

const Card = (props) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className='column is-3'>
      <div
        className='card hand-cursor'
        onClick={() => {
          history.push("/cohortes/" + String(props.data.id) + location.search);
        }}>
        <div className='card-content'>
          <div className='content'>
            <p className='is-size-7'>{props.data.demandeur?.name}</p>
            <p className='title is-5'>{props.data.label}</p>
            <p className='is-size-7 mb-0 is-uppercase has-text-grey-light'>À traiter</p>
            <p className='is-size-7 mb-0'>{Number(props.data.candidates_to_be_handled)} / {Number(props.data.candidates_count)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};