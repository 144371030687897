import React from "react";

const GenericTextFilter = ({width, value, label, placeholder, onChange}) => {  
  return (
    <div className={`column is-${width ? width : 3}`}>
      <div className="field">
        <label className="label is-small">
          {label ? label : "Filtre"}
        </label>
        <div className="control">
          <input
            type="text"
            className="input"
            placeholder={placeholder}
            defaultValue={value}
            onChange={(e)=>{if(!onChange) return; onChange(e.target.value);}}
          />
        </div>
      </div>
    </div>
  );
};

export default GenericTextFilter;
