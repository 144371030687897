import {
  abcdkColorRulesByInterpretationId,
  abcdkBaseColors,
} from "../../constants/abcdkColorRulesByInterpretationId";

const cellStyleValueGetter = (params) => {
  const interpretation_id = params.data.resultats?.abcdk?.find((abcdk) => {
    return abcdk.label === params.colDef.label;
  })?.interpretation_id;
  if (
    interpretation_id === undefined ||
    params.value === null ||
    !abcdkColorRulesByInterpretationId.hasOwnProperty(interpretation_id)
  ) {
    return null;
  }
  let style = Object.keys(
    abcdkColorRulesByInterpretationId[interpretation_id]
  ).find(
    (key) =>
      params.value >=
        abcdkColorRulesByInterpretationId[interpretation_id][key].min &&
      params.value <=
        abcdkColorRulesByInterpretationId[interpretation_id][key].max
  );
  if (style !== undefined) {
    if (abcdkBaseColors[style] !== undefined) {
      return {
        backgroundColor: abcdkBaseColors[style].bgColor,
        "font-weight": abcdkBaseColors[style].fontWeight,
      };
    }
  }
};

export default cellStyleValueGetter;
