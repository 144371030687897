import React from "react";
import useLocationSearch from "../../utils/use-location-search";
import GenericTextFilter from "./GenericTextFilter";

const CandidatsFilter = ({ width }) => {
  const { value, setValue } = useLocationSearch("candidat");

  return <GenericTextFilter label='Candidat' placeholder='Nom du candidat' width={width} value={value} onChange={setValue} />;
};

export default CandidatsFilter;
