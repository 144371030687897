import React from "react";

export default function Footer() {
  return (
    <footer className='has-background-light'>
      <div className='content has-text-centered is-small'>
        <a href='https://www.e-potentiel.com' className='subtle has-text-primary' target='blank'>
          © {new Date().getFullYear()}
        </a>{" "}
        - v{process.env.REACT_APP_VERSION}
      </div>
    </footer>
  );
}
