import _ from "lodash";
import React from "react";
import { CohortesCandidatsService } from "../../../services/api/commonServices";

const AssignToStatusButton = (props) => {
  const { selectedRows, cohortId, gridRef, assignedCB } = props;
  const onChange = (e) => {
    gridRef.current.api.showLoadingOverlay();

    CohortesCandidatsService(cohortId)
      .bulkPatch("ids=" + _.map(selectedRows, "id").join(), {
        statut: e.target.value,
      })
      .then(() => {
        gridRef.current.api.deselectAll();
        gridRef.current.api.hideOverlay();
        if (assignedCB) assignedCB();
      });
    // Reset to default value (without triggering a change event)
    setTimeout(() => (e.target.selectedIndex = 0), 500);
  };

  return (
    <div className="select is-small is-outlined">
      <select
        defaultValue={""}
        onChange={onChange}
        disabled={!selectedRows?.length}
      >
        <option value="" disabled>
          Assigner un statut à la sélection
        </option>
        <option value="">(Aucun)</option>
        <option value="Accepté">Accepté</option>
        <option value="Refusé">Refusé</option>
        <option value="Attente">Attente</option>
        <option value="À l'étude">À l'étude</option>
        <option value="Abandon">Abandon</option>
        <option value="Désisté">Désisté</option>
        <option value="Retrait temporaire">Retrait temporaire</option>
      </select>
    </div>
  );
};

export default AssignToStatusButton;
