import { getUniqueColsFromRowData } from "./helpers/dynamicCols";
import MiniBarChartCellRenderer from "./cellRenderers/common/MiniBarChartCellRenderer";
import barChartValueGetter from "./valueSettersGetters/competences/barChart";
import moyenneValueGetter from "./valueSettersGetters/competences/moyenne";
import scorePercentValueGetter from "./valueSettersGetters/competences/scorePercent";

const cellClassRules = {
  "rag-red": "x !== null && x >=0 && x <= 55",
  "rag-green": "x !== null && x >= 80 && x <= 100",
};

const baseColDefs = [
  {
    headerName: "Moy.",
    columnGroupShow: null,
    filter: false,
    width: 70,
    headerTooltip: "Moyenne des scores compétences",
    valueGetter: moyenneValueGetter,
  },
  {
    headerName: "",
    columnGroupShow: "closed",
    width: 100,
    filter: false,
    valueGetter: barChartValueGetter,
    cellRendererFramework: MiniBarChartCellRenderer,
    cellRendererParams: { id: "competences-mini-chart" },
  },
];

// Here 'dynamic' refers to the fact that
// the columns are retreived from the Grid data
const getDynColDefs = (comps) => {
  return comps?.map((comp) => ({
    key: comp.colId,
    colId: comp.colId,
    headerName: comp.label ? comp.label.substring(0, 3) : comp.label,
    headerTooltip: comp.label,
    columnGroupShow: "open",
    type: "numericColumn",
    chartDataType: "series",
    filter: false,
    valueGetter: (params) => scorePercentValueGetter(params, comp),
    cellClassRules: cellClassRules,
  }));
};


// Adds compétence columns to the grid dynamically
let uniqueCompCols = [];
export const addCompetencesColumns = (gridRef) => {
  // Get unqique compétences from rowData resultats
  uniqueCompCols = getUniqueColsFromRowData(gridRef.current.props.rowData, "competences", "comp_");
  // If none, stop here
  if (uniqueCompCols.length === 0) return;

  // Get current grid colDefs
  let gridColDefs = gridRef.current.api.getColumnDefs();

  // Append compétences colDefs (baseColDefs and dynimicColDefs) to current grid's colDefs
  gridColDefs.push({
    headerName: "Compétences",
    groupId: "competences",
    suppressColumnsToolPanel: true,
    children: [...baseColDefs, ...getDynColDefs(uniqueCompCols)],
  });

  // Set new colDefs back to grid
  gridRef.current.api.setColumnDefs(gridColDefs);
};

export const getCompColsMetadata = () => uniqueCompCols;