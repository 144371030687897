import React, { useLayoutEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";

const GenericAGGrid = ({ data, error, gridOptions, height, children, setGridParamsCb }) => {
  // eslint-disable-next-line no-unused-vars
  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const defaultColDef = {
    resizable: true,
    enableCellChangeFlash: false,
    editable: false,
    sortable: true,
    filter: true,
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    if  (setGridParamsCb) setGridParamsCb(params);
  };

  const onFirstDataRendered = (params) => {
    // window.onresize = () => gridApi.sizeColumnsToFit();
  };

  useLayoutEffect(() => {
    //gridApi?.sizeColumnsToFit();
  }, [gridApi]);

  return (
    <div
      className='ag-theme-alpine'
      style={{
        width: "100%",
        height: height ? height : 1024,
      }}>
      {error && <p className='has-text-danger'>Erreur{error.statusText ? `: ${error.statusText}` : ""}</p>}
      <AgGridReact
        rowData={data}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        stopEditingWhenGridLosesFocus={true}
        animateRows={true}
        enableCellTextSelection={true}
        accentedSort={true}
        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, FiltersToolPanelModule, ExcelExportModule]}
        singleClickEdit={true}>
        {children}
      </AgGridReact>
    </div>
  );
};

export default GenericAGGrid;
