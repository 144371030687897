// Create chart via grid integrated charts (not standalone)
let chartRef = null;

const createChart = (params) => {
  // destroy existing chart
  if (chartRef) {
    chartRef.destroyChart();
  }

  // Title
  let title =
    params.cohortData?.demandeur?.name +
    " / " +
    params.cohortData?.formation?.label +
    " / " +
    params.cohortData?.label;

  // Get subtitle from applied filters
  let subtitle = params.title + ": ";
  // if (statutFilterValue !== undefined) {
  //   subtitle += "Statut: " + statutFilterValue + ", ";
  // }
  // if (groupeFilterValue !== undefined) {
  //   subtitle +=
  //     "Groupe: " +
  //     groupes?.find((grp) => {
  //       return grp.id === parseInt(groupeFilterValue);
  //     })?.label +
  //     ", ";
  // }
  // if (candidatFilterValue !== undefined) {
  //   subtitle += "Candidat: " + candidatFilterValue + ", ";
  // }
  // if (codePermanentFilterValue !== undefined) {
  //   subtitle += "Code permanent: " + codePermanentFilterValue + ", ";
  // }
  // Remove last ", ";
  subtitle = subtitle.replace(/,\s*$/, "");
  // Show subtitle for empty filters
  if (subtitle === params.title + ": ") {
    subtitle += "Pour la cohorte entière";
  }

  const column = params.gridRef?.current?.columnApi.getColumn(params.columnId);
  
  if (!column?.parent?.groupId){
    // console.log("Column not found");
    // Maybe show toast, but this should not happen
    return;
  }

  // NOTE 1: columns used in the chart need to be visible (colapsed group will not work, will show "No data available to be charted")
  params.gridRef.current.columnApi.setColumnGroupOpened(
    column.parent.groupId,
    true
  );

  // Sort grid so the chart is in descending order
  params.gridRef.current.columnApi.applyColumnState({
    state: [
      {
        colId: params.columnId,
        sort: "desc",
      },
    ],
    defaultState: { sort: null },
  });

  // NOTE 2: columns used in the chart need to be declared in the grid with chartDataType property (ex: chartDataType="series" or chartDataType="category")
  var chartParams = {
    cellRange: {
      columns: [params.columnId, "nom_complet"],
    },
    chartType: "groupedBar",
    aggFunc: "sum",
    chartThemeOverrides: {
      common: {
        title: {
          enabled: true,
          text: title,
        },
        subtitle: {
          enabled: true,
          text: subtitle,
        },
        axes: {
          number: {
            tick: { count: 4 },
            min: 0,
            max: 100,
          },
        },
      },
    },
  };

  chartRef = params.gridRef.current.api.createRangeChart(chartParams);
};

export default createChart;