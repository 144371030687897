import { useEffect } from "react";
import { useAPIError } from "../utils/use-api-error";
import DefaultErrorModal from "./apiErrors/DefaultErrorModal";
import Error401Modal from "./apiErrors/Error401Modal";
import Error403Modal from "./apiErrors/Error403Modal";

/**
 * 
 * This should be replaced by react-error-boundary
 * https://www.npmjs.com/package/react-error-boundary

 */
export default function APIErrorNotification({children}) {
  const { apiError, removeApiError } = useAPIError();

  useEffect(()  =>  {
    if  (apiError)  {
      removeApiError();
    }
  },  [apiError, removeApiError]);

  if (!apiError) {
    return null;
  } else if (apiError.status === 401) {
    return <Error401Modal />;
  } else if (apiError.status === 403) {
    return <Error403Modal />;
  } else if ((apiError.status >= 500 && apiError.status <= 599)){
    return <DefaultErrorModal />;
  }

  return {children};
  
}
