import { useEffect } from "react";
import Swal from "sweetalert2";

const Error403Modal = () => {
  useEffect(() => {
    Swal.fire({
      title: "Hmmm...",
      text: "Vous n'avez pas l'autorisation requise",
      icon: "warning",
      cancelButtonText: "Fermer",
    });
  });

  return null;
};

export default Error403Modal;
