const groupeIdValueSetter = (params) => {
    //  When editing groupe id, make sure
    //  to update groupe (label) column.
    
    // Find groupe label based on id
    let label = params.context.groupes.find((refData) => refData.id === Number(params.newValue))?.label;
    
    // Set new values
    params.data.groupe = label ? label : null;
    params.data.groupe_id = params.newValue;
    return true;
}

export default groupeIdValueSetter;
