import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/use-auth";

export default function Header() {
  // const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  function logout() {
    auth.signout(() => {
      history.push("/login");
    });
  }

  if (auth.user) {
    return (
      <nav className='navbar has-background-dark' role='navigation' aria-label='main navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <button className='navbar-burger' aria-label='menu' aria-expanded='false' data-target='navbarBasicExample'>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </button>
          </div>
          <div id='navbarBasicExample' className='navbar-menu'>
            <div className='navbar-start'>
              <Link to={'/'+ location.search} className={`navbar-item ${location.pathname === "/" ? "has-text-black has-background-light" : "has-text-white"}`}>
                Accueil
              </Link>
              <Link to='/seances' className={`navbar-item ${location.pathname.includes("/seances") ? "has-text-black has-background-light" : "has-text-white"}`}>
                Séances
              </Link>
              <Link to='/cohortes' className={`navbar-item ${location.pathname.includes("/cohortes") ? "has-text-black has-background-light" : "has-text-white"}`}>
                Cohortes
              </Link>
            </div>

            <div className='navbar-end'>
              <div className='navbar-item'>
                <button className='button is-primary is-outlined' onClick={logout}>
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  return <Redirect to='/login' />;
}
