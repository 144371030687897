const sideBar = {
  toolPanels: [
    // {
      //   id: "customFilters",
      //   labelDefault: "Filtres",
      //   iconKey: "filter",
      //   labelKey: "customFilters",
      //   toolPanel: "customFiltersToolPanel",
      // },
      {
        id: "columns",
        labelDefault: "Colonnes",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressColumnFilter: true,
          suppressColumnExpandAll: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSyncLayoutWithGrid: true,
          suppressColumnMove: true,
          suppressColumnSelectAll: true
        },
      },
      {
        id: "filters",
        labelDefault: "Filtres",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
        toolPanelParams: {
          suppressExpandAll: true,
          suppressFilterSearch: true,
          suppressSyncLayoutWithGrid: true
        },
      },
  ],
  // defaultToolPanel: "customFilters",
  position: "left",
};

export default sideBar;
