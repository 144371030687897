import React from "react";
import _ from "lodash";
import BarchartPlugins from "./plugins/barchartPlugins";
import ChartContainer from "../../../components/charts/ChartContainer";

export default function PersonnaliteChart({ title, data, width, height }) {
  const options = {
    type: 'bar',
    plugins: [BarchartPlugins.dottedLines],
    data: {
      labels: _.map(data, 'label'),
      datasets: [
        {
          data: _.map(data, 'value'),
          backgroundColor: 'rgba(132, 206, 230, 1)',
        },
      ],
    },
    avgMin: 45,
    avgMax: 55,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      layout: {
        padding: {
          left: 10,
          top: 0,
          bottom: 0,
          right: 10,
        },
      },
      tooltips: {
        displayColors: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: false,
              max: 85,
              min: 15,
              stepSize: 25,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              callback: function (value, index, values) {
                value = "" + value; // Make sure we deal with a string
                return value.substring(0, 1);
              },
            },
            gridLines: { display: false },
          },
        ],
      },
    },
  };
  return (
    <ChartContainer
      id="personnalite-chart-canvas"
      title={title}
      data={data}
      options={options}
      width={width}
      height={height}
    />
  );
}
