const DefaultColDef = {
  sortable: true,
  filter: true,
  suppressMenu: true,
  resizable: true,
  editable: false,
  disable: true,
  singleClickEdit: true,
  width: 70,
  // suppressSizeToFit for "flex: 1" on column Commentaire to work
  suppressSizeToFit: true,
};

export default DefaultColDef;
