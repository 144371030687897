import React, { useState, useRef, useLayoutEffect } from 'react';
import CompetencesChart from "./charts/competencesChart";
import AptitudesChart from "./charts/aptitudesChart";
import PersonnaliteChart from "./charts/personnaliteChart";
import PistesInterventions from './charts/pistesInterventions';
import Notes from "./Notes";
import _ from 'lodash';

const DetailCellRenderer = (params) => {
  let [currentOpenTabIndex, setCurrentOpenTabIndex] = useState(() => 0);
  const tabButtonsRef = useRef();
  const tabNavigatorRef = useRef();

  const {data} = params;

  useLayoutEffect(() => {
    const buttons = tabButtonsRef.current.getElementsByTagName('li');
    _.forEach(buttons, (el) => el.classList.remove('is-active'));
    buttons[currentOpenTabIndex].classList.add('is-active');

    let tabs = tabNavigatorRef.current.getElementsByClassName('tab-wrapper');
    _.forEach(tabs, (el) => el.classList.add('is-hidden'));
    tabs[currentOpenTabIndex].classList.remove('is-hidden');
  }, [currentOpenTabIndex]);
  
  

  const defaultHeight = '185px';
  const tabWrapperStyle = {
    display: 'flex',
    padding: '5px 15px 5px',
    height: '250px',
    flexWrap: 'wrap',
    overflow: 'auto',
    justifyContent: 'space-between',
  };

  return (
    <div
      className='container'
      style={{
        padding: '20px 15px 5px',
        height: '300px',
      }}>
        <div className='tabs is-small is-centered is-toggle is-toggle-rounded'>
          <ul ref={tabButtonsRef}>
            <li>
              <a href='/graphiques' onClick={(e) => { e.preventDefault(); setCurrentOpenTabIndex(0)}}>
                Graphiques
              </a>
            </li>
            <li>
              <a href='/pistes' onClick={(e) => {e.preventDefault(); setCurrentOpenTabIndex(1)}}>
                Pistes d'interventions&ensp;{data.resultats?.pistes ? `(${data.resultats?.pistes.length})` : ''}
              </a>
            </li>
            <li>
              <a href='/pistes' onClick={(e) => { e.preventDefault(); setCurrentOpenTabIndex(2)}}>
                Notes&ensp;{data.util_sess_notes ? '∗' : ''}
              </a>
            </li>
          </ul>
        </div>
      <div ref={tabNavigatorRef}>
        <div className='tab-wrapper' style={tabWrapperStyle}>
          <CompetencesChart title='Compétences' data={data.resultats?.competences} width='25%' height={defaultHeight} />
          <AptitudesChart title='Aptitudes' data={data.resultats?.aptitudes} width='15%' height={defaultHeight} />
          <PersonnaliteChart title='Personnalité' data={data.resultats?.personnalite} width='60%' height={defaultHeight} />
        </div>
        <div className='tab-wrapper is-hidden' style={tabWrapperStyle}>
          <PistesInterventions data={data.resultats?.pistes} />
        </div>
        <div className='tab-wrapper is-hidden' style={{ ...tabWrapperStyle, overflow: 'hidden' }}>
          <Notes params={params} />
        </div>
      </div>
    </div>
  );
};

export default DetailCellRenderer;
