import MiniBarChartCellRenderer from "./cellRenderers/common/MiniBarChartCellRenderer";
import barChartValueGetter from "./valueSettersGetters/abcdk/barChart";
import cellStyleValueGetter from "./valueSettersGetters/abcdk/cellStyle";
import scorePercentValueGetter from "./valueSettersGetters/abcdk/scorePercent";
import abcdkColumnsOrder from "./constants/abcdkColumnsOrder";
import { getUniqueColsFromRowData } from "./helpers/dynamicCols";

const baseColDefs = [
  {
    headerName: "",
    columnGroupShow: "closed",
    width: 100,
    filter: false,
    valueGetter: barChartValueGetter,
    cellRendererFramework: MiniBarChartCellRenderer,
    cellRendererParams: { id: "abcdk-mini-chart" },
  },
];

// Here 'dynamic' refers to the fact that
// the columns are retreived from the Grid data
const getDynColDefs = (abcdks) => {
  return abcdks?.map((abcdk) => ({
    key: abcdk.colId,
    colId: abcdk.colId,
    label: abcdk.label,
    headerName: abcdk.label ? abcdk.label.substring(0, 3) : abcdk.label,
    headerTooltip: abcdk.label,
    columnGroupShow: "open",
    type: "numericColumn",
    chartDataType: "series",
    valueGetter: (params) => scorePercentValueGetter(params, abcdk),
    cellStyle: cellStyleValueGetter,
    filter: false,
  }));
};

// Adds abcdk columns to the grid dynamically
let uniqueABCDKCols = [];
export const addABCDKColumns = (gridRef) => {
  // Get abcdks from rowData
  uniqueABCDKCols = getUniqueColsFromRowData(gridRef.current.props.rowData, "abcdk", "abc_");

  // If none, stop here
  if (uniqueABCDKCols.length === 0) return;

  // Sort ABCDK with custom order
  uniqueABCDKCols.sort(function (o1, o2) {
    return abcdkColumnsOrder[o1.label] - abcdkColumnsOrder[o2.label];
  });

  // Build colDefs array for each compétence
 getDynColDefs(uniqueABCDKCols);
  
  // Get current grid colDefs
  let gridColDefs = gridRef.current.api.getColumnDefs();

  // Append aptitudes colDefs to the grid's colDefs
  gridColDefs.push({
    headerName: "ABCDK",
    groupId: "abcdk",
    suppressColumnsToolPanel: true,
    children: [...baseColDefs, ...getDynColDefs(uniqueABCDKCols)],
    filter: false,
  });

  // Set new colDefs back to grid
  gridRef.current.api.setColumnDefs(gridColDefs);
};

export const getABCDKColsMetadata = () => uniqueABCDKCols;
