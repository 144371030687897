import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../utils/use-auth";

const Error401Modal = () => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    Swal.fire({
      title: "Hmmm...",
      text: "Votre session a expiré",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Fermer",
      confirmButtonText: "Se reconnecter",
    }).then((answer) => {
      if (answer.isConfirmed) {
        auth.signout(() => {
          history.push({
            pathname: "/login",
            state: { from: location },
          });
        });
      }
    });
  });

  return null;
};

export default Error401Modal;
