import request from "../../utils/request";

const GenericService = (route) => {
  const get = (filter, id) => {
    return request({
      url: `/${route}${id ? '/' + id : ''}${filter ? filter : ''}`,
      method: 'GET',
    });
  };

  const post = (data) => {
    return request({
      url: `/${route}`,
      data: data,
      method: 'POST',
    });
  };

  const patch = (id, data) => {
    return request({
      url: `/${route}/${id}`,
      data: data,
      method: 'PATCH',
    });
  };

  const bulkPatch = (filter, data) => {
    return request({
      url: `/${route}?${filter}`,
      data: data,
      method: "PATCH",
    });
  };

  const remove = (id) => {
    return request({
      url: `/${route}/${id}`,
      method: 'DELETE',
    });
  };

  return {
    get,
    post,
    patch,
    bulkPatch,
    remove,
  };
};

export const CohortesService = () => GenericService("cohortes");
export const CohortesCandidatsService = (cohorteId) => GenericService(`cohortes/${cohorteId}/cohortes-candidats`);
export const SeancesService = () => GenericService("seances");
export const SessionTestsService = () => GenericService('utilisateurSession');
export const ResultatsService = () => GenericService("resultats");
export const EtablissementsService = () => GenericService("demandeurs");
export const FormationsService = () => GenericService("formations");
export const CandidatesDemandeurService = (demandeurId) => GenericService(`demandeurs/${demandeurId}/candidates`);
export const CandidatesService = () => GenericService(`candidates`);
export const GroupesService = (cohorteId) => GenericService(`cohortes/${cohorteId}/groups`);
export const CohorteGroupeService = (cohorteId) => GenericService(`cohortes/${cohorteId}/groups`);
