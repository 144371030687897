import { aptitudesColorRulesByInterpretationId } from "../../constants/aptitudesColorRulesByInterpretationId";

const cellStyleValueGetter = (params) => {
  let interpretation_id = params.data.resultats?.aptitudes?.find((apt) => {
    return apt.label === params.colDef.label;
  })?.interpretation_id;
  if (
    interpretation_id === undefined ||
    params.value === null ||
    !aptitudesColorRulesByInterpretationId.hasOwnProperty(interpretation_id)
  ) {
    return null;
  }
  let color = Object.keys(
    aptitudesColorRulesByInterpretationId[interpretation_id]
  ).find(
    (key) =>
      params.value >=
        aptitudesColorRulesByInterpretationId[interpretation_id][key].min &&
      params.value <=
        aptitudesColorRulesByInterpretationId[interpretation_id][key].max
  );
  if (color !== undefined) {
    return { color: color, "font-weight": "bold" };
  }
};

export default cellStyleValueGetter;
