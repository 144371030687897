import React, { useRef, useEffect } from "react";
import Chart from "chart.js";

export default function ChartContainer({ title, id, data, options, width, height }) {
  const ctx = useRef();

  useEffect(() => {
    const context = ctx.current;
    let myBarChart = new Chart(context, options);

    return () => myBarChart.destroy();
  }, [data, options]);
  return (
    <div className='chart' style={{ position: "relative", width: `${width ? width : "33%"}`, height: "185px" }}>
      <p className='has-text-centered has-text-weight-medium is-uppercase is-size-7 mb-4 has-text-grey'>{title}</p>
      <div className='chart-canvas-wrapper' style={{ position: "relative", width: "100%", height: "100% " }}>
        <canvas ref={ctx} id={id} />
      </div>
    </div>
  );
}
