import React from "react";

const GroupeFilter = ({ gridRef, groups }) => {
  const [groupe, setGroupe] = React.useState("");

  const onChangeHandler = (e) => {
    let instance = gridRef.current.api.getFilterInstance("groupe_id");
    if (e.target.value === "") {
      instance.setModel(null);
    } else {
      instance.setModel({ type: "equal", filter: e.target.value });
    }
    setGroupe(e.target.value);
    instance.applyModel();
    gridRef.current.api.onFilterChanged();
  };
  return (
    <div
      className="field"
      style={{
        minWidth: 104,
      }}
    >
      <label className="label is-small has-text-weight-normal">Groupe</label>
      <div className="select is-small is-fullwidth">
        <select value={groupe} onChange={onChangeHandler}>
          <option value=""></option>
          {groups?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default GroupeFilter;
