import React from "react";

const StatusFilter = ({ gridRef }) => {
  const [value, setValue] = React.useState("");

  const onChangeHandler = (e) => {
    let instance = gridRef.current.api.getFilterInstance("statut");
    if (e.target.value === "") {
      instance.setModel(null);
    } else {
      instance.setModel({ type: "equal", filter: e.target.value });
    }
    setValue(e.target.value);
    instance.applyModel();
    gridRef.current.api.onFilterChanged();
  };
  return (
    <div className="field">
      <label className="label is-small has-text-weight-normal">Statut</label>
      <div className="select is-small is-fullwidth">
        <select value={value} onChange={onChangeHandler}>
          <option value=""></option>
          <option value="Accepté">Accepté</option>
          <option value="Refusé">Refusé</option>
          <option value="Attente">Attente</option>
          <option value="À l'étude">À l'étude</option>
          <option value="Abandon">Abandon</option>
          <option value="Désisté">Désisté</option>
          <option value="Retrait temporaire">Retrait temporaire</option>
        </select>
      </div>
    </div>
  );
};
export default StatusFilter;
