import React from "react";
import { useHistory, useLocation } from "react-router-dom";
const Card = (props) => (
  <div className='column is-3'>
    <div className='card hand-cursor' onClick={() => props.history.push(`seances/${props.data.id}${props.location.search}`)}>
      <div className='card-content'>
        <div className='content'>
          <p className='title is-5'>{props.data.label}</p>
          <p className='is-size-7' title='Début / Fin'>
            {props.data.dt_debut}
            <br />
            {props.data.dt_fin}
          </p>
          <p className='is-size-7 mb-0 is-uppercase has-text-grey-light'>Terminées</p>
          <p className='is-size-7 mb-0'>{props.data.utilisateur_sessions_completed_count} / {props.data.utilisateur_sessions_count}</p>
        </div>
      </div>
    </div>
  </div>
);
export default function SeancesCards(props) {
  const history = useHistory();
  const location = useLocation();

  const { data } = props;
  if (!data) {
    return null;
  }

  return (
    <div className='columns is-multiline'>
      {data.map((seance) => {
        return <Card key={seance.id} data={seance} history={history} location={location} />;
      })}
    </div>
  );
}
