import React from "react";
//import { AgChartsReact } from "ag-charts-react";
// Needs packages ag-charts-community and ag-charts-react (npm install) to work (not installed since we don't use it atm). FT

const GroupViewChart = ({ params, close }) => {
// const options = {
//   title: {
//       text: params.title
//   },
//   data: params.data,
//   legend: {
//     enabled: false
//   },
//   series: [{
//     type: 'bar',
//     xKey: 'nom_complet',
//     yKeys: ['score_percent'],
//     yNames: ['score_percent'],
//     // Fills are the colours to cycle through for different bars (stacked or grouped charts). See https://www.ag-grid.com/react-charts/bar-series/#api-reference
//     fills: [
//       'rgba(132, 206, 230, 1)'
//     ],
//     // Same thing as fills, but for strokes (bar contour)
//     strokes: [
//       ['#aa4520', '#b07513', '#3d803d', '#2d768d', '#2e3e8d', '#6c2e8c', '#8c2d46', '#5f5f5f']
//     ],
//     strokeOpacity: 0.5,
//   }],
//   axes: [
//     {
//       type: 'number',
//       position: 'bottom',
//     },
//     {
//       type: 'category',
//       position: 'left',
//       label: {
//         formatter: (params) => ''
//       }
//     },
//   ],
// };  

  return (
    <div className={`modal ${params.visible ? "is-active" : ""}`}>
      <div className='modal-background' onClick={() => close()}></div>
      <div className='modal-card'>
        <header className='modal-card-head'>
          <p className='modal-card-title'>Vue d'ensemble - {params.type}</p>
          <button className='delete' aria-label='close' onClick={() => close()}></button>
        </header>
        <section className='modal-card-body'>          
          {params.data && (
            <>
              {/* <AgChartsReact options={options} /> */}
            </>
          )}
        </section>
        <footer className='modal-card-foot'>
          <button className='button' onClick={() => close()}>
            Fermer
          </button>
        </footer>
      </div>
    </div>    
  );
};

export default GroupViewChart;
