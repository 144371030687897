import React from "react";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { Switch, Route } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import APIErrorProvider from "./utils/use-api-error";
import AppWrapper from "./components/AppWrapper";
import APIErrorNotification from "./components/APIErrorNotification";
import Header from "./components/Header";
import MainWrapper from "./components/MainWrapper";
import Footer from "./components/Footer";

import Seances from "./pages/seances/seances";
import { Error404 } from "./pages/errors/404";
import Home from "./pages/home/home";
import Cohorts from "./pages/cohorts/cohorts";
import Candidates from "./pages/candidates/candidates";
import CohortDetails from "./pages/cohortsV1_OBSOLETE/cohortDetails";
import Cohort from "./pages/cohorts/cohort";
import CandidateDetails from "./pages/candidates/candidateDetails";
import SeanceDetails from "./pages/seances/seanceDetails";

export default function Main() {
  LicenseManager.setLicenseKey(
    "CompanyName=e-Potentiel,LicensedApplication=e-Profil,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011394,ExpiryDate=23_October_2021_[v2]_MTYzNDk0MzYwMDAwMA==dfe0453a4f4e889af28b649119b54b85"
  );
  return (
    <ErrorBoundary>
      <AppWrapper>
        <APIErrorProvider>
          <APIErrorNotification />
          <Header />
          <MainWrapper>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/seances" component={Seances} />
              <Route path="/seances/:id" component={SeanceDetails} />
              <Route exact path="/cohortes" component={Cohorts} />
              <Route path="/cohortesV1/:id" component={CohortDetails} />
              <Route path="/cohortes/:id" component={Cohort} />
              <Route exact path="/candidats" component={Candidates} />
              <Route path="/candidats/:id" component={CandidateDetails} />
              <Route component={Error404} />
            </Switch>
          </MainWrapper>
          <Footer />
        </APIErrorProvider>
      </AppWrapper>
    </ErrorBoundary>
  );
}
