import _ from "lodash";
import React from "react";
import { CohortesCandidatsService } from "../../../services/api/commonServices";

const AssignToGroupButton = (props) => {
  const { selectedRows, cohortId, gridRef, assignedCB, groups } = props;
  const onChange = (e) => {
    gridRef.current.api.showLoadingOverlay();

    CohortesCandidatsService(cohortId)
      .bulkPatch("ids=" + _.map(selectedRows, "id").join(), {
        groupe_id: e.target.value,
      })
      .then(() => {
        gridRef.current.api.deselectAll();
        gridRef.current.api.hideOverlay();
        if (assignedCB) assignedCB();
      });
    // Reset to default value (without triggering a change event)
    setTimeout(() => (e.target.selectedIndex = 0), 500);
  };

  return (
    <div className="select is-small is-outlined">
      <select
        defaultValue={""}
        onChange={onChange}
        disabled={!selectedRows?.length || !groups?.length}
      >
        <option value="" disabled>
          Assigner un groupe à la sélection
        </option>
        <option value="">(Aucun)</option>
        {groups?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AssignToGroupButton;
