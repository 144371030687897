// Iterates over rowData (candidate results)
// and returns array of unique compétences
// with colId, label and interpretationId
export const getUniqueColsFromRowData = (rowData, resultsNodes, prefix) => {
  let uniqueCols = [];
  rowData?.forEach((item) => {
    item.resultats?.[resultsNodes]?.forEach((col, index) => {
      if (!uniqueCols.find((uniqCol) => uniqCol.label === col.label)) {
        uniqueCols.push({
          colId: prefix + col.label,
          label: col.label,
          interpretation_id: col.interpretation_id,
        });
      }
    });
  });
  
  return uniqueCols;
};