import candidat from "./columnDefs/candidat";
import checkBox from "./columnDefs/checkBox";
import commentaire from "./columnDefs/commentaire";
import infosCandidat from "./columnDefs/infoCandidat";
import selectedOverTotalValueGetter from "./columnDefs/valueSettersGetters/checkbox/selectedOverTotal";

const columnDefs = [
  {
    headerName: "",
    // For the headerValueGetter (that dsiplays number of rows) to work properly,
    // we need to call params.api.refreshHeader()
    // upon grid onRowSelected and grid onFilterChanged
    headerValueGetter: selectedOverTotalValueGetter,
    children: checkBox,
    suppressFiltersToolPanel: true,
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Candidat",
    openByDefault: true,
    groupId: "candidat",
    children: candidat, // Code permanent, Âge, Sexe, MA, Statut, Groupe
    suppressColumnsToolPanel: true,
  },
  {
    headerName: "Infos candidat",
    openByDefault: true,
    groupId: "infoCandidats",
    children: infosCandidat, // Code permanent, Âge, Sexe, MA, Statut, Groupe
  },
  {
    headerName: "Commentaire",
    openByDefault: true,
    groupId: "commentaire",
    children: commentaire,
    flex:1,
    // suppressSizeToFit for "flex: 1" to work
    suppressSizeToFit: false,
    
  },
  // Compétences columns added dynamically by calling addCompetencesColumns,
  // Aptitudes columns added dynamically by calling addAptitudesColumns,
  // ABCDK columns added dynamically by calling addABCDKColumns,
];

export default columnDefs;
