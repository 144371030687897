import React, { Children } from "react";

const ButtonBar = (props) => {
  return (
    <div id="button-bar" className="mb-2 mt-3 ml-5 mr-5">
      <div className="level">
        <div className="level-left">
          {Children.map(Children.toArray(props.left), (child, index) => {
            return (
              <div className="level-item" key={index}>
                {child}
              </div>
            );
          })}
        </div>
        <div className="level-right">
          {Children.map(Children.toArray(props.right), (child, index) => {
            return (
              <div className="level-item" key={index}>
                {child}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ButtonBar;
