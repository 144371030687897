import React, { useState } from "react";
import AddCandidateModal from "../modals/AddCandidateModal";

const AddCandidateButton = (props) => {
  const { cohortId, addedCB } = props;
  let [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <button
        className="button is-small is-outlined"
        onClick={() => setModalVisible(true)}
      >
        Ajouter un candidat
      </button>

      <AddCandidateModal
        cohorteId={cohortId}
        visible={modalVisible}
        close={() => setModalVisible(false)}
        onCandidateAddedCB={addedCB}
      />
    </>
  );
};

export default AddCandidateButton;
