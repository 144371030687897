import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../utils/use-auth";

export default function PrivateRoute({ component: Component, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ props, location }) =>
        auth.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
