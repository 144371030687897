const groupeValueSetter = (params) => {
    //  When we select a group label from the dropdown list, make sure
    //  to update groupe_id column.
    // (!) The cellValueChanged event will be triggered from groupe_id.
    
    // Find groupe_id based on label
    let id = params.context.groupes.find((refData) => refData.label === params.newValue)?.id;
    
    // Set new values
    params.node.setDataValue("groupe_id", id ? id : null);
    // params.node.setData({...params.data, groupe_id: id ? id : null});
    params.data.groupe = params.newValue;
    return true;
}

export default groupeValueSetter;
