export const abcdkBaseColors = {
  yellow: { bgColor: "rgb(255, 229, 152)", fontWeight: "normal" },
  yellowBold: { bgColor: "rgb(255, 229, 152)", fontWeight: "bold" },
  green: { bgColor: "rgb(168, 208, 141)", fontWeight: "normal" },
  greenBold: { bgColor: "rgb(168, 208, 141)", fontWeight: "bold" },
  blue: { bgColor: "rgb(156, 194, 229)", fontWeight: "normal" },
  blueBold: { bgColor: "rgb(156, 194, 229)", fontWeight: "bold" },
  red: { bgColor: "rgb(255, 133, 135)", fontWeight: "normal" },
  redBold: { bgColor: "rgb(255, 133, 135)", fontWeight: "bold" },
  orange: { bgColor: "rgb(244, 176, 131)", fontWeight: "normal" },
  orangeBold: { bgColor: "rgb(244, 176, 131)", fontWeight: "bold" },
};

export const abcdkColorRulesByInterpretationId = {
  // Analytique
  163: {
    yellow: { min: 45, max: 69 },
    yellowBold: { min: 70, max: 100 },
  },
  // Pragmatique
  164: {
    green: { min: 45, max: 69 },
    greenBold: { min: 70, max: 100 },
  },
  // Emotivo-Relationnel
  165: {
    blue: { min: 45, max: 69 },
    blueBold: { min: 70, max: 100 },
  },
  // Visuo-Imaginatif
  166: {
    red: { min: 45, max: 69 },
    redBold: { min: 70, max: 100 },
  },
  // Kinesthesique
  162: {
    orange: { min: 45, max: 69 },
    orangeBold: { min: 70, max: 100 },
  },
};
