import { useEffect, useState } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

const useLocationSearch = (param) => {
  const history = useHistory();
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const [value, setValue] = useState(parsed[param] ? parsed[param] : undefined);

  useEffect(() => {
    let parsed = queryString.parse(location.search);
    if (value) {
      parsed[param] = value;
      history.replace({
        search: queryString.stringify(parsed),
      });
    } else {
      delete parsed[param];
      history.replace({
        search: queryString.stringify(parsed),
      });
    }
  }, [param, value, history, location.search]);

  return {
    value,
    setValue,
  };
};

export default useLocationSearch;
