import { AgGridColumn } from "@ag-grid-community/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EtablissementsFilter from "../../components/filters/EtablissementsFilter";
import FormationsFilter from "../../components/filters/FormationsFilter";
// import Datepicker from "../../components/filters/Datepicker";
import GenericTextFilter from "../../components/filters/GenericTextFilter";
import GenericAGGrid from "../../components/grids/GenericAGGrid";
import ListFilters from "../../components/ListFilters";
import { useSwrSeances } from "../../utils/use-swr";

export default function Seances(props) {
  const history = useHistory();
  const { location } = props;

  const { data, isError } = useSwrSeances(location.search);

  const [gridParams, setGridParams] = useState();
  const [labelFilterValue, setLabelFilterValue] = useState();

  useEffect(() => {
    if  (!gridParams) return;
    gridParams.api.getFilterInstance("label").setModel({
      filterType: "string",
      type: "contains",
      filter: labelFilterValue,
    });
    gridParams.api.getFilterInstance("label").applyModel();
    gridParams.api.onFilterChanged();
  }, [labelFilterValue, gridParams]);

  const gridOptions = {
    onRowClicked: (params) => {
      history.push(`${history.location.pathname}/${params.data.id}`);
    },
  };

  return (
    <div className='container'>
      <div id='Seances' className='section'>
        <h1 className='title'>Séances de tests</h1>

        {isError && <p>Erreur</p>}
        <ListFilters>
          <GenericTextFilter label='Séance' placeholder='Nom de la séance' width='3' onChange={setLabelFilterValue} />
          <EtablissementsFilter width='3' />
          <FormationsFilter width='3' />
        </ListFilters>
        <GenericAGGrid data={data} error={isError} gridOptions={gridOptions} setGridParamsCb={setGridParams}>
          <AgGridColumn headerName='ID' field='id' hide={true} />
          <AgGridColumn headerName='Nom' field='label' />
          <AgGridColumn headerName='Nb candidats' field='utilisateur_sessions_count' />
          <AgGridColumn headerName='Sess. de tests terminées' field='utilisateur_sessions_completed_count' />
          <AgGridColumn headerName='Début' field='dt_debut' sort='desc' />
          <AgGridColumn headerName='Fin' field='dt_fin' />
        </GenericAGGrid>
      </div>
    </div>
  );
}