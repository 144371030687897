import request from "../../utils/request";

export const AdminServices = () => {
  const cohorteCompileResults = (id, payload) => {
    console.log(id)
    return request({
      url: `/compileResults/cohortes/${id}`,
      data: payload,
      method: 'POST',
    });
  };

  return {
    cohorteCompileResults
  };
};
