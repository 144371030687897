import { AgGridColumn } from "@ag-grid-community/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import GenericTextFilter from "../../components/filters/GenericTextFilter";
import GenericAGGrid from "../../components/grids/GenericAGGrid";
import ListFilters from "../../components/ListFilters";
import { CandidatesService } from "../../services/api/commonServices";
import { useSwrCandidates } from "../../utils/use-swr";

export default function Candidates(props) {
  const location = useLocation();
  const [pageIndex, setPageIndex] = useState(0);
  const { data, isError } = useSwrCandidates(`page=${pageIndex}&`+location.search);
  return (
    <div className="section">
      <div id="candidates" className="container">
        <h1 className="title">Candidats</h1>
        <Filters />
        <GenericAGGrid
          data={data}
          error={isError}
          apiService={CandidatesService()}
        >
          <AgGridColumn headerName="Nom" field="nom" />
          <AgGridColumn headerName="Prenom" field="prenom" />
          <AgGridColumn headerName="Code permanent" field="code_permanent" />
          <AgGridColumn headerName="Courriel" field="courriel" />
          <AgGridColumn headerName="Création" field="created" />
          <AgGridColumn headerName="Modification" field="updated" />
        </GenericAGGrid>
      </div>
      <button onClick={setPageIndex(pageIndex-1)} style={{display: 'hidden'}}>Précédent</button>
      <button onClick={setPageIndex(pageIndex+1)} style={{display: 'hidden'}}>Suivant</button>
    </div>
  );
}

const Filters = React.memo(() => {
  return (
    <ListFilters>
      <GenericTextFilter
        width="3"
        label="Recherche"
        placeholder="Nom, courriel, code permanent, &hellip;"
      ></GenericTextFilter>
    </ListFilters>
  );
});
