import React from "react";

export function Error404() {
  return (
    <div id="error404" className="container">
      <section className="section">
        <h1>Page introuvable</h1>
      </section>
    </div>
  );
}
